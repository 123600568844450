import React from "react";
import { Popup } from "semantic-ui-react";

import IDate from "../data/Date";
import dateIntoString from "../util/dateConverts/dateIntoString";
import ItalicFM from "../util/styledMessages/ItalicFM";
import UncertainIcon from "./priest-profile/priest-data-uncertain-check/UncertainIcon";

interface IDateDisplayProps {
    date: IDate;
    uncertain?: boolean;
}

const DateDisplay: React.FC<IDateDisplayProps> = (props: IDateDisplayProps) => {
    const { date, uncertain } = props;

    const dateText = dateIntoString(date);

    if (dateText) {
        if (date.comment) {
            return (
                <>
                    <Popup content={date.comment} key={date.comment} trigger={<span>{dateText}</span>} /> {uncertain && <UncertainIcon />}
                </>
            );
        } else {
            return (
                <>
                    {dateText}
                    {uncertain && <UncertainIcon />}
                </>
            );
        }
    } else {
        if (date.comment) {
            return (
                <>
                    <i style={{ color: "gray" }}>{date.comment}</i>
                    {uncertain && <UncertainIcon />}
                </>
            );
        } else {
            return (
                <>
                    <ItalicFM id="general.noData" />
                    {uncertain && <UncertainIcon />}
                </>
            );
        }
    }
};

export default DateDisplay;

import _ from "lodash";
import React from "react";
import { Container, FormField, Grid, Input } from "semantic-ui-react";

import "../../../static/priestProfile.css";
import BoldFM from "../../../util/styledMessages/BoldFM";
import ItalicFM from "../../../util/styledMessages/ItalicFM";
import UncertainCheckbox from "../priest-data-uncertain-check/UncertainCheckbox";
import UncertainIcon from "../priest-data-uncertain-check/UncertainIcon";

interface IPriestGeneralDataRowContainerProps {
    labelName: string;
    dataName?: string;
    data: string;
    isGrey?: boolean;
    editMode: boolean;
    setPriest(labelName: string, value: any): any;
    uncertain?: boolean;
}

const PriestGeneralDataRowContainer: React.FC<IPriestGeneralDataRowContainerProps> = (props: IPriestGeneralDataRowContainerProps) => {
    const { labelName, dataName, data, editMode, isGrey, setPriest, uncertain } = props;

    const handleChange = (event: any) => {
        dataName ? setPriest(dataName, event.target.value) : setPriest(labelName, event.target.value);
    };

    const handleUncertainChange = () => {
        setPriest("uncertainFields", labelName);
    };

    return (
        <Grid.Row id={isGrey ? "grey" : ""}>
            <Grid.Column align="center" className="text">
                <BoldFM id={"profile." + labelName} showColon />
            </Grid.Column>
            <Grid.Column className="text" name={labelName}>
                {editMode ? (
                    <FormField style={{ display: "flex", alignItems: "center" }}>
                        <Input id={isGrey ? "grey" : ""} onChange={handleChange} value={data} />
                        {!_.isNil(uncertain) && !_.isNil(handleUncertainChange) && (
                            <UncertainCheckbox handleUncertainChange={handleUncertainChange} uncertain={uncertain} />
                        )}
                    </FormField>
                ) : data ? (
                    <Container>
                        {data}
                        {uncertain && <UncertainIcon />}
                    </Container>
                ) : (
                    <ItalicFM id="general.noData" />
                )}
            </Grid.Column>
        </Grid.Row>
    );
};

export default PriestGeneralDataRowContainer;

import IDate, { NewDate } from "../Date";
import Religion from "../enums/Religion";

interface IChild {
    name: string;
    birthPlace: string;
    birthDate: IDate;
    religion: Religion;
}

export const NewChild: IChild = {
    name: "",
    birthPlace: "",
    birthDate: NewDate,
    religion: Religion.REFORMED
};

export default IChild;

import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Icon, Popup } from "semantic-ui-react";

import "../../../static/semanticUiOverwrites.css";

const UncertainIcon: React.FC = () => (
    <Popup content={<FM id="label.uncertain.data" />} trigger={<Icon className="uncertainIcon" name="question circle outline" onHover />} />
);

export default UncertainIcon;

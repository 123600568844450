import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const generatePDF = (name: string): void => {
    const content = document.getElementById("pdfContent");
    if (content !== null) {
        html2canvas(content, { allowTaint: true, backgroundColor: "rgba(255,255,255,255)" }).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF("p", "mm");
            let position = 0;

            doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save(name + ".pdf", { returnPromise: false });
        });
    }
};

export default generatePDF;

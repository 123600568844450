import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import IEgyhazmegyeData from "../../../data/parish/EgyhazmegyeData";
import BoldFM from "../../../util/styledMessages/BoldFM";
import ItalicFM from "../../../util/styledMessages/ItalicFM";
import EgyhazmegyeDropdown from "../../egyhazmegye/EgyhazmegyeDropdown";

interface IParishEgyhazmegyeContainerProps {
    editMode: boolean;
    egyhazmegye: IEgyhazmegyeData;
    setEgyhazmegye(value: any): any;
}

const ParishEgyhazmegyeContainer: React.FC<IParishEgyhazmegyeContainerProps> = (props: IParishEgyhazmegyeContainerProps) => {
    const { egyhazmegye, editMode, setEgyhazmegye } = props;

    return (
        <Grid.Row style={{ paddingBottom: "15px" }}>
            {!editMode ? (
                <>
                    <BoldFM id={"parish.kerulet"} showColon style={{ paddingRight: "5px" }} />
                    {egyhazmegye.egyhazkerulet.name} (<ItalicFM id={"parish.bishop"} showColon />{" "}
                    <NavLink to={`/priests/profile/${egyhazmegye.egyhazkerulet.puspokID}`}> {egyhazmegye.egyhazkerulet.puspokName}</NavLink>){" - "}
                    {egyhazmegye.name} (<ItalicFM id={"parish.dean"} showColon />{" "}
                    <NavLink to={`/priests/profile/${egyhazmegye.esperesID}`}> {egyhazmegye.esperesName}</NavLink>)
                </>
            ) : (
                <EgyhazmegyeDropdown defaultSelectionID={egyhazmegye?.ID} setEgyhazmegye={setEgyhazmegye} />
            )}
        </Grid.Row>
    );
};

export default ParishEgyhazmegyeContainer;

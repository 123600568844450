import React from "react";
import { Grid } from "semantic-ui-react";

interface IPageWrapperOneColumnProps {
    children: JSX.Element;
}

const PageWrapperOneColumn: React.FC<IPageWrapperOneColumnProps> = (props: IPageWrapperOneColumnProps) => {
    const { children } = props;

    return (
        <Grid centered className="wrapperGrid" divided relaxed="very" stackable>
            <Grid.Column className="contentColumn" textAlign="center">
                {children}
            </Grid.Column>
        </Grid>
    );
};

export default PageWrapperOneColumn;

import React from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { FormattedMessage as FM, useIntl } from "react-intl";
import "semantic-ui-css/semantic.min.css";
import { Dropdown, Form, Header, Icon } from "semantic-ui-react";

import Religion from "../../data/enums/Religion";
import StudyStatus from "../../data/enums/StudyStatus";
import IPriestFilters, { filterEndYear, filterStartYear } from "../../data/priest/PriestFilters";
import BoldFM from "../../util/styledMessages/BoldFM";
import ReligionDropdown from "../priest-profile/ReligionDropdown";
import StudyStatusDropdown from "../priest-profile/StudyStatusDropdown";

const refreshButtonProps = {
    name: "refresh",
    basic: true,
    circular: true,
    compact: true,
    icon: true
};

const verifiedOptions = [
    {
        key: "all",
        text: <FM id="label.all" />,
        value: "all"
    },
    {
        key: "verified",
        text: <FM id="profile.verified.isVerifiedPopupShort" />,
        value: "true",
        label: <Icon color="green" name="check circle outline" />
    },
    {
        key: "unverified",
        text: <FM id="profile.verified.notVerifiedPopupShort" />,
        value: "false",
        label: <Icon color="grey" name="question circle outline" />
    }
];

interface ISearchPriestsControllerProps {
    chartPage?: boolean;
    filters: IPriestFilters;
    setFilters(newFilters: object): void;
    resetPagination(): void;
}

const SearchPriestsController: React.FC<ISearchPriestsControllerProps> = (props: ISearchPriestsControllerProps) => {
    const { chartPage, filters, setFilters, resetPagination } = props;
    const { formatMessage: fm } = useIntl();

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
        resetPagination();
    };

    const handleRangeChange = (value: any, startLabel: string, endLabel: string) => {
        setFilters({
            ...filters,
            [startLabel]: value.min.toString(),
            [endLabel]: value.max.toString()
        });
    };

    const resetRanges = (startLabel: string, endLabel: string) => {
        setFilters({
            ...filters,
            [startLabel]: filterStartYear.toString(),
            [endLabel]: filterEndYear.toString()
        });
    };

    const handleVerifiedChange = (value: string) => {
        if (value === "all") {
            value = "";
        }
        setFilters({
            ...filters,
            ["verifiedProfile"]: value
        });
    };

    const handleStudyStatusChange = (_event: any, data: { value: StudyStatus | "all" }) => {
        const value = data.value;
        setFilters({
            ...filters,
            ["studyStatus"]: value === "all" ? "" : value
        });
    };
    const handleReligionChange = (_: any, data: { value: Religion | "all" }) => {
        const value = data.value;
        setFilters({
            ...filters,
            ["religion"]: value === "all" ? "" : value
        });
    };

    return (
        <Form>
            <Header as="h1" className="controllerHeader">
                {chartPage ? <FM id="charts.title" /> : <FM id="search.priests" />}
            </Header>
            <Form.Input id="name" label={fm({ id: "label.name" })} name="name" onChange={handleChange} value={filters.name} />
            <Form.Input
                id="fullTextSearch"
                label={fm({ id: "label.fullTextSearch" })}
                name="fullTextSearch"
                onChange={handleChange}
                style={{ marginBottom: "-5em" }}
                value={filters.fullTextSearch}
            />
            <BoldFM id="label.priest.fullTextSearch" style={{ fontSize: "10px" }} /> <br /> <br />
            <Form.Input id="birthTown" label={fm({ id: "label.birthTown" })} name="birthTown" onChange={handleChange} value={filters.birthTown} />
            <Form.Input id="placement" label={fm({ id: "label.placement" })} name="placement" onChange={handleChange} value={filters.placement} />
            <Form.Input
                id="area"
                label={fm({ id: "label.area" })}
                name="area"
                onChange={handleChange}
                style={{ marginBottom: "0.5em" }}
                value={filters.area}
            />
            <BoldFM id="profile.studyStatus" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Field width={13}>
                    <StudyStatusDropdown
                        defaultValue={filters.studyStatus === "" ? "all" : filters.studyStatus}
                        displayOptionForAll
                        setValue={handleStudyStatusChange}
                    />
                </Form.Field>
            </Form.Group>
            <BoldFM id="profile.religion" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Field width={13}>
                    <ReligionDropdown
                        defaultValue={filters.religion === "" ? "all" : filters.religion}
                        displayOptionForAll
                        setValue={handleReligionChange}
                    />
                </Form.Field>
            </Form.Group>
            <BoldFM id="label.priest.verifiedProfile" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Field width={13}>
                    <Dropdown
                        defaultValue={filters.verifiedProfile === "" ? "all" : filters.verifiedProfile}
                        name="verifiedProfile"
                        onChange={(_, data) => {
                            handleVerifiedChange(data.value as string);
                        }}
                        options={verifiedOptions}
                        selection
                    />
                </Form.Field>
            </Form.Group>
            <BoldFM id="label.priest.activeYears" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Button onClick={() => resetRanges("activeStart", "activeEnd")} {...refreshButtonProps} width={2}>
                    <Icon name="refresh" />
                </Form.Button>
                <Form.Field width={1} />
                <Form.Field width={13}>
                    <InputRange
                        maxValue={filterEndYear}
                        minValue={filterStartYear}
                        onChange={(value: any) => handleRangeChange(value, "activeStart", "activeEnd")}
                        value={{ min: Number(filters.activeStart), max: Number(filters.activeEnd) }}
                    />
                </Form.Field>
            </Form.Group>
            <BoldFM id="label.priest.birthYear" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Button onClick={() => resetRanges("birthStart", "birthEnd")} {...refreshButtonProps} width={2}>
                    <Icon name="refresh" />
                </Form.Button>
                <Form.Field width={1} />
                <Form.Field width={13}>
                    <InputRange
                        maxValue={filterEndYear}
                        minValue={filterStartYear}
                        onChange={(value: any) => handleRangeChange(value, "birthStart", "birthEnd")}
                        value={{ min: Number(filters.birthStart), max: Number(filters.birthEnd) }}
                    />
                </Form.Field>
            </Form.Group>
            <BoldFM id="label.priest.deathYear" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Button onClick={() => resetRanges("deathStart", "deathEnd")} {...refreshButtonProps} width={2}>
                    <Icon name="refresh" />
                </Form.Button>
                <Form.Field width={1} />
                <Form.Field width={13}>
                    <InputRange
                        maxValue={filterEndYear}
                        minValue={filterStartYear}
                        onChange={(value: any) => handleRangeChange(value, "deathStart", "deathEnd")}
                        value={{ min: Number(filters.deathStart), max: Number(filters.deathEnd) }}
                    />
                </Form.Field>
            </Form.Group>
            <BoldFM id="label.priest.subscriptionYear" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Button onClick={() => resetRanges("subscriptionStart", "subscriptionEnd")} {...refreshButtonProps} width={2}>
                    <Icon name="refresh" />
                </Form.Button>
                <Form.Field width={1} />
                <Form.Field width={13}>
                    <InputRange
                        maxValue={filterEndYear}
                        minValue={filterStartYear}
                        onChange={(value: any) => handleRangeChange(value, "subscriptionStart", "subscriptionEnd")}
                        value={{ min: Number(filters.subscriptionStart), max: Number(filters.subscriptionEnd) }}
                    />
                </Form.Field>
            </Form.Group>
            <BoldFM id="label.priest.graduationYear" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Button onClick={() => resetRanges("graduationStart", "graduationEnd")} {...refreshButtonProps} width={2}>
                    <Icon name="refresh" />
                </Form.Button>
                <Form.Field width={1} />
                <Form.Field width={13}>
                    <InputRange
                        maxValue={filterEndYear}
                        minValue={filterStartYear}
                        onChange={(value: any) => handleRangeChange(value, "graduationStart", "graduationEnd")}
                        value={{ min: Number(filters.graduationStart), max: Number(filters.graduationEnd) }}
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    );
};
export default SearchPriestsController;

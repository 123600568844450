import React from "react";
import { Button, Card, Divider, Header, Icon } from "semantic-ui-react";

import { NewChild } from "../../../data/priest/Child";
import IPriestProfileData from "../../../data/priest/PriestProfileData";
import { NewSpouse } from "../../../data/priest/Spouse";
import BoldFM from "../../../util/styledMessages/BoldFM";
import ItalicFM from "../../../util/styledMessages/ItalicFM";
import ErrorMsg from "../../ErrorMsg";
import ProfilePlaceholder from "../../ProfilePlaceholder";
import ParentCard from "./ParentCard";
import PriestFamilyChildList from "./PriestFamilyChildList";
import PriestFamilySpouseList from "./PriestFamilySpouseList";

interface IPriestFamilyContainerProps {
    priest: IPriestProfileData;
    loading: boolean;
    showError: boolean;
    editMode: boolean;
    setPriest(newPriest: IPriestProfileData): void;
}

const PriestFamilyContainer: React.FC<IPriestFamilyContainerProps> = (props: IPriestFamilyContainerProps) => {
    const { priest, setPriest, loading, showError, editMode } = props;

    const handleNewChild = () => {
        if (priest) {
            if (priest.children) {
                priest.children.splice(0, 0, { ...NewChild });
                setPriest({ ...priest, children: priest.children });
            } else {
                setPriest({ ...priest, children: [{ ...NewChild }] });
            }
        }
    };

    const handleNewSpouse = () => {
        if (priest) {
            if (priest.spouses) {
                priest.spouses.splice(0, 0, { ...NewSpouse });
                setPriest({ ...priest, spouses: priest.spouses });
            } else {
                setPriest({ ...priest, spouses: [{ ...NewSpouse }] });
            }
        }
    };

    const handleChangeOnParent = (labelName: string, value: any) => {
        setPriest({ ...priest, [labelName]: value });
    };

    if (showError) {
        return <ErrorMsg errorMessage={"error.connection"} />;
    } else if (loading || !priest) {
        return <ProfilePlaceholder />;
    } else {
        return (
            <>
                <Header as="h2">
                    <ItalicFM id="profile.parents" />
                </Header>
                {priest.father.name || priest.mother.name || editMode ? (
                    <Card.Group doubling itemsPerRow={2} stackable>
                        <ParentCard editMode={editMode} handleChangeOnParent={handleChangeOnParent} label="father" parentData={priest.father} />
                        <ParentCard editMode={editMode} handleChangeOnParent={handleChangeOnParent} label="mother" parentData={priest.mother} />
                    </Card.Group>
                ) : (
                    <ItalicFM id="general.noData" />
                )}

                <Divider />

                <Header as="h2">
                    {priest.spouses && priest.spouses.length > 1 ? <ItalicFM id="profile.spouses" /> : <ItalicFM id="profile.spouse" />}
                </Header>
                {editMode && (
                    <Button basic circular color="violet" icon id="addNewSpouse" labelPosition="left" onClick={handleNewSpouse}>
                        <Icon color="violet" name="add" size="large" />
                        <BoldFM id="profile.addNewSpouse" />
                    </Button>
                )}

                <PriestFamilySpouseList editMode={editMode} priest={priest} setPriest={setPriest} />
                <Divider style={{ marginTop: "2em" }} />

                <Header as="h2">
                    {priest.children && priest.children.length > 1 ? <ItalicFM id="profile.children" /> : <ItalicFM id="profile.child" />}
                </Header>
                {editMode && (
                    <Button basic circular color="violet" icon id="addNewChild" labelPosition="left" onClick={handleNewChild}>
                        <Icon color="violet" name="add" size="large" />
                        <BoldFM id="profile.addNewChild" />
                    </Button>
                )}
                <PriestFamilyChildList editMode={editMode} priest={priest} setPriest={setPriest} />
            </>
        );
    }
};

export default PriestFamilyContainer;

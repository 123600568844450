import IDate, { NewDate } from "../Date";
import ILocation, { NewLocation } from "./Location";

interface IConsecration {
    location: ILocation;
    place: string;
    date: IDate;
}

export const NewConsecration: IConsecration = {
    location: NewLocation,
    place: "",
    date: NewDate
};

export default IConsecration;

import ICoordinates, { NewCoordinates } from "../Coordinates";
import IDate, { NewDate } from "../Date";

interface IPlacement {
    ID?: number;
    placeID: number;
    place: string;
    startDate: IDate;
    endDate: IDate;
    coordinates: ICoordinates;
}

export const NewPlacement: IPlacement = {
    placeID: 0,
    place: "",
    startDate: NewDate,
    endDate: NewDate,
    coordinates: NewCoordinates
};

export default IPlacement;

import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { NavLink } from "react-router-dom";
import { Card, Header } from "semantic-ui-react";

import ISuggestion from "../../data/suggestion/Suggestion";
import "../../static/semanticUiOverwrites.css";
import SuggestionButtons from "./SuggestionButtons";
import SuggestionDetails from "./SuggestionDetails";

interface ISuggestionCardProps {
    suggestion: ISuggestion;
}

const SuggestionCard: React.FC<ISuggestionCardProps> = (props: ISuggestionCardProps) => {
    const { suggestion } = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [resolved, setResolved] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    if (resolved) {
        return (
            <Card fluid key={suggestion.ID}>
                <Card.Content>{showError ? <FM id="suggestion.resolve.error" /> : <FM id="suggestion.resolve.success" />}</Card.Content>
            </Card>
        );
    } else {
        return (
            <>
                {modalOpen && (
                    <SuggestionDetails setOpen={setModalOpen} setResolved={setResolved} setShowError={setShowError} suggestion={suggestion} />
                )}
                <Card as={NavLink} className="suggestionCard" fluid key={suggestion.ID} to={window.location.pathname + window.location.search}>
                    <Card.Content onClick={() => setModalOpen(true)}>
                        <Card.Header>
                            <Header as="h2">
                                {`${suggestion.targetName} (`}
                                <FM id={suggestion.targetType} />
                                {")"}
                            </Header>
                        </Card.Header>
                        <Card.Meta>
                            <FM id="suggestion.issuer" /> {`${suggestion.proposerName} (${suggestion.proposerEmail})`}
                        </Card.Meta>
                        <Card.Meta>
                            <FM id="suggestion.date" />
                            {new Date(suggestion.creationDate).toLocaleString(localStorage.getItem("languageKey") || "hu", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric"
                            })}
                        </Card.Meta>
                        <Card.Description>
                            <div className="suggestionText">{suggestion.text}</div>
                        </Card.Description>
                    </Card.Content>
                    {!suggestion.accepted ? (
                        <Card.Content extra>
                            <SuggestionButtons
                                setOpen={setModalOpen}
                                setResolved={setResolved}
                                setShowError={setShowError}
                                suggestionID={suggestion.ID}
                                targetID={suggestion.targetId}
                                targetType={suggestion.targetType}
                            />
                        </Card.Content>
                    ) : (
                        <Card.Content extra>
                            <FM id="suggestion.acceptedBy" />: {suggestion.acceptedBy}
                        </Card.Content>
                    )}
                </Card>
            </>
        );
    }
};

export default SuggestionCard;

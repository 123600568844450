import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Form } from "semantic-ui-react";

import Religion from "../../data/enums/Religion";
import "../../static/priestProfile.css";
import BoldFM from "../../util/styledMessages/BoldFM";
import ItalicFM from "../../util/styledMessages/ItalicFM";
import ReligionDropdown from "./ReligionDropdown";

interface ICardRowForReligionProps {
    labelName: string;
    index?: number;
    data: Religion | "all" | "";
    editMode: boolean;
    setValue(labelName: string, value: any, index?: number): any;
}

const CardRowForReligion: React.FC<ICardRowForReligionProps> = (props: ICardRowForReligionProps) => {
    const { labelName, data, editMode, setValue, index } = props;

    const handleReligionChange = (_: any, data: { value: Religion }) => {
        const value = data.value;
        if (typeof index === "number") {
            setValue(labelName, value, index);
        } else {
            setValue(labelName, value);
        }
    };

    return (
        <Form>
            <Form.Field inline>
                <BoldFM id={"profile." + labelName} showColon />{" "}
                {editMode ? (
                    <ReligionDropdown defaultValue={data} displayOptionForAll={false} setValue={handleReligionChange} />
                ) : data ? (
                    <FM id={"profile.religion." + data} />
                ) : (
                    <ItalicFM id="general.noData" />
                )}
            </Form.Field>
        </Form>
    );
};

export default CardRowForReligion;

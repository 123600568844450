import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Card, Container, Header, Pagination } from "semantic-ui-react";

import IPriestMin from "../../data/priest/PriestMin";
import IPriestProfileData from "../../data/priest/PriestProfileData";
import CardPlaceholder from "../CardPlaceholder";
import ErrorMsg from "../ErrorMsg";
import ExportPriestButton from "../export/ExportPriestButton";
import PriestCard from "./PriestCard";

const PAGESIZE = 10;

interface ISearchPriestsContainerProps {
    priests: IPriestProfileData[] | undefined;
    loading: boolean;
    showError: boolean;
    currentPage: number;
    setCurrentPage(newPage: number): void;
}

const SearchPriestsContainer: React.FC<ISearchPriestsContainerProps> = (props: ISearchPriestsContainerProps) => {
    const { priests, loading, showError, currentPage, setCurrentPage } = props;

    const handlePageChange = (_: any, { activePage }: any) => setCurrentPage(activePage);

    if (showError) {
        return <ErrorMsg errorMessage={"error.connection"} />;
    } else if (loading) {
        return <CardPlaceholder />;
    } else if (!priests || priests.length === 0) {
        return (
            <>
                <CardPlaceholder />
                <Header as="h2">
                    <FM id="search.noResult" />
                </Header>
            </>
        );
    } else {
        return (
            <>
                <Container style={{ paddingLeft: "80%" }}>
                    <ExportPriestButton priests={priests} />
                </Container>
                {currentPage === 1 && (
                    <Header as="h2" style={{ fontWeight: "normal" }}>
                        <span style={{ fontWeight: "bold" }}>{priests.length} </span>
                        <FM id="search.size" />
                    </Header>
                )}
                <Card.Group doubling itemsPerRow={2} stackable>
                    {priests.slice((currentPage - 1) * PAGESIZE, currentPage * PAGESIZE).map((priest: IPriestMin) => (
                        <PriestCard key={priest.ID} priest={priest} />
                    ))}
                </Card.Group>
                <Container style={{ marginTop: "3em" }} textAlign="center">
                    <Pagination activePage={currentPage} onPageChange={handlePageChange} totalPages={Math.ceil(priests.length / PAGESIZE)} />
                </Container>
            </>
        );
    }
};

export default SearchPriestsContainer;

import React from "react";

import IConsecration from "../../../data/priest/Consecration";
import IPriestProfileData from "../../../data/priest/PriestProfileData";
import "../../../static/priestProfile.css";
import PriestGeneralDataRowContainer from "./PriestGeneralDataRowContainer";
import PriestGeneralDataRowContainerForDate from "./PriestGeneralDataRowContainerForDate";

interface IConsecrationProps {
    consecrationData: IConsecration;
    editMode: boolean;
    priest: IPriestProfileData;
    setPriest(newPriest: IPriestProfileData): void;
    uncertainPlace?: boolean;
    uncertainDate?: boolean;
}

const ConsecrationRow: React.FC<IConsecrationProps> = (props: IConsecrationProps) => {
    const { consecrationData, editMode, priest, setPriest, uncertainPlace, uncertainDate } = props;

    const handleChange = (labelName: string, value: any) => {
        // Uncertain fields were not initialized
        if (labelName === "uncertainFields" && !priest.uncertainFields) {
            setPriest({ ...priest, [labelName]: [value] });
            return;
        }

        // Uncertain fields not changed, consecration data was edited
        if (labelName !== "uncertainFields") {
            setPriest({ ...priest, ["consecration"]: { ...consecrationData, [labelName]: value } });
            return;
        }

        // Removing field from uncertain fields
        if (priest.uncertainFields!.includes(value)) {
            const newUncertainFields = priest!.uncertainFields!.filter((field) => field !== value);
            setPriest({ ...priest, [labelName]: newUncertainFields });
            return;
        }

        // Adding field to uncertain fields
        const newUncertainFields = [...priest.uncertainFields!];
        newUncertainFields.push(value);
        setPriest({ ...priest, [labelName]: newUncertainFields });
    };

    return (
        <>
            <PriestGeneralDataRowContainerForDate
                data={consecrationData.date}
                dataName="date"
                editMode={editMode}
                isGrey
                labelName="consecrationDate"
                setPriest={handleChange}
                uncertain={uncertainDate}
            />
            <PriestGeneralDataRowContainer
                data={consecrationData.place}
                dataName="place"
                editMode={editMode}
                labelName="consecrationPlace"
                setPriest={handleChange}
                uncertain={uncertainPlace}
            />
        </>
    );
};

export default ConsecrationRow;

import React from "react";
import { Grid } from "semantic-ui-react";

import IDate from "../../../data/Date";
import "../../../static/priestProfile.css";
import BoldFM from "../../../util/styledMessages/BoldFM";
import DateDisplay from "../../DateDisplay";
import DateInput from "../../DateInput";

interface IPriestGeneralDataRowContainerForDateProps {
    labelName: string;
    dataName?: string;
    data: IDate;
    isGrey?: boolean;
    editMode: boolean;
    setPriest(labelName: string, value: any): any;
    uncertain?: boolean;
}

const PriestGeneralDataRowContainerForDate: React.FC<IPriestGeneralDataRowContainerForDateProps> = (
    props: IPriestGeneralDataRowContainerForDateProps
) => {
    const { labelName, dataName, data, editMode, isGrey, setPriest, uncertain } = props;

    const handleChange = (newDate: IDate) => {
        dataName ? setPriest(dataName, newDate) : setPriest(labelName, newDate);
    };

    const handleUncertainChange = () => {
        setPriest("uncertainFields", labelName);
    };

    return (
        <Grid.Row id={isGrey ? "grey" : ""}>
            <Grid.Column align="center" className="text">
                <BoldFM id={"profile." + labelName} showColon />
            </Grid.Column>
            <Grid.Column className="text" name={labelName}>
                {editMode ? (
                    <DateInput
                        changeDate={handleChange}
                        date={data}
                        handleUncertainChange={handleUncertainChange}
                        isGrey={isGrey}
                        uncertain={uncertain}
                    />
                ) : (
                    <DateDisplay date={data} uncertain={uncertain} />
                )}
            </Grid.Column>
        </Grid.Row>
    );
};

export default PriestGeneralDataRowContainerForDate;

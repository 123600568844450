import IDate, { NewDate } from "../Date";
import Religion from "../enums/Religion";

interface IParent {
    name: string;
    job: string;
    birthDate: IDate;
    deathDate: IDate;
    religion: Religion;
}

export const NewParent: IParent = {
    name: "",
    job: "",
    birthDate: NewDate,
    deathDate: NewDate,
    religion: Religion.REFORMED
};

export default IParent;

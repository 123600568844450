import React from "react";
import { Grid } from "semantic-ui-react";

interface IPageWrapperTwoColumnsProps {
    children: JSX.Element[];
}

const PageWrapperTwoColumns: React.FC<IPageWrapperTwoColumnsProps> = (props: IPageWrapperTwoColumnsProps) => {
    const { children } = props;

    return (
        <Grid centered className="wrapperGrid" columns={2} divided relaxed="very" stackable>
            <Grid.Column className="controlColumn" computer={4} stretched tablet={4} textAlign="left" verticalAlign="top">
                {children[0]}
            </Grid.Column>
            <Grid.Column className="contentColumnScroll" computer={10} tablet={12} textAlign="left">
                {children[1]}
            </Grid.Column>
        </Grid>
    );
};

export default PageWrapperTwoColumns;

import IDate, { NewDate } from "../Date";

interface IPriestReference {
    place: string;
    date: IDate;
}

export const NewPriestReference: IPriestReference = { place: "", date: NewDate };

export default IPriestReference;

import React, { useState } from "react";
import { FormattedMessage as FM, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";

import doPut from "../../requests/doPut";
import useSession from "../../util/useSession/useSession";

interface ISuggestionButtonsProps {
    suggestionID: number;
    targetID: number;
    targetType: string;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setResolved?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowError?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuggestionButtons: React.FC<ISuggestionButtonsProps> = (props: ISuggestionButtonsProps) => {
    const { suggestionID, targetID, targetType, setOpen, setResolved, setShowError } = props;

    const { user } = useSession();
    const { formatMessage: fm } = useIntl();

    const [acceptConfirmOpen, setAcceptConfirmOpen] = useState(false);
    const [declineConfirmOpen, setDeclineConfirmOpen] = useState(false);

    const resolveSuggestion = (state: boolean) => {
        setOpen && setOpen(false);
        setResolved && setResolved(true);
        const suggestionObject = {
            ID: suggestionID,
            accepted: state ? "accepted" : "declined",
            acceptedBy: user?.name + "(" + user?.email + ")"
        };
        const showError = doPut("/suggestion", suggestionObject);
        setShowError && setShowError(showError);
    };

    const profileLink = targetType === "priest" ? `/${targetType}s/profile/${targetID}` : `/${targetType}es/profile/${targetID}`;

    return (
        <>
            <Confirm
                cancelButton={fm({ id: "general.no" })}
                confirmButton={fm({ id: "general.yes" })}
                content={fm({ id: "suggestion.confirm.accept" })}
                onCancel={() => setAcceptConfirmOpen(false)}
                onConfirm={() => resolveSuggestion(true)}
                open={acceptConfirmOpen}
            />
            <Confirm
                cancelButton={fm({ id: "general.no" })}
                confirmButton={fm({ id: "general.yes" })}
                content={fm({ id: "suggestion.confirm.decline" })}
                onCancel={() => setDeclineConfirmOpen(false)}
                onConfirm={() => resolveSuggestion(false)}
                open={declineConfirmOpen}
            />
            <Button color="violet" inverted onClick={() => setAcceptConfirmOpen(true)}>
                <FM id="suggestion.resolve.accept" />
            </Button>
            <Button color="grey" inverted onClick={() => setDeclineConfirmOpen(true)}>
                <Button.Content style={{ color: "black" }}>
                    <FM id="suggestion.resolve.decline" />
                </Button.Content>
            </Button>
            <Button as={NavLink} inverted secondary style={{ marginLeft: "55px" }} to={profileLink}>
                <FM id={`suggestion.resolve.open.${targetType}`} />
            </Button>
        </>
    );
};

export default SuggestionButtons;

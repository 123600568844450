import ICoordinates, { NewCoordinates } from "../Coordinates";

interface ILocation {
    placeName: string;
    postalCode: string;
    countryName: string;
    countyName: string;
    coordinates: ICoordinates;
}

export const NewLocation: ILocation = {
    placeName: "",
    postalCode: "",
    countryName: "",
    countyName: "",
    coordinates: NewCoordinates
};

export default ILocation;

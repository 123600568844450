import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Button, Modal } from "semantic-ui-react";

import generatePDF from "../../../util/pdfConversion/generatePDFHelper";

interface IPriestPDFModalActionsProps {
    closePreview: () => void;
    priestName: string;
}

const PriestPDFModalActions: React.FC<IPriestPDFModalActionsProps> = (props: IPriestPDFModalActionsProps) => {
    const { closePreview, priestName } = props;

    const handleSave = () => {
        generatePDF(priestName);
        closePreview();
    };

    return (
        <Modal.Actions>
            <Button color="grey" onClick={closePreview}>
                <FM id="general.cancel" />
            </Button>
            <Button color="violet" content={<FM id="profile.pdfExport" />} icon="print" labelPosition="right" onClick={handleSave} />
        </Modal.Actions>
    );
};

export default PriestPDFModalActions;

import React from "react";
import { Card } from "semantic-ui-react";

import IParishGeneralData from "../../../data/parish/ParishGeneralData";
import API_URL from "../../../requests/apiConfig";
import "../../../static/priestProfile.css";
import ItalicFM from "../../../util/styledMessages/ItalicFM";
import FileCard from "../../common/file-upload/FileCard";
import PictureCard from "../../common/picture-upload/PictureCard";
import { ParishProfileFileType } from "./ParishProfileFileContainer";

interface IParishProfileFileListProps {
    fileType: ParishProfileFileType;
    editMode: boolean;
    parish: IParishGeneralData;
    parishFiles: string[];
    asyncDeleteFile(url: string): void;
    setParish(newParish: IParishGeneralData): void;
}

const ParishProfileFileList: React.FC<IParishProfileFileListProps> = (props: IParishProfileFileListProps) => {
    const { fileType, editMode, parish, parishFiles, asyncDeleteFile, setParish } = props;

    const handleDeleteFile = (index: number, file: string) => {
        asyncDeleteFile(file);
        parish.files.splice(index, 1);
        setParish({ ...parish, files: parish.files });
    };

    const handleDeletePicture = (index: number, picture: string) => {
        asyncDeleteFile(picture);
        parish.pictures.splice(index, 1);
        setParish({ ...parish, pictures: parish.pictures });
    };

    if (!parishFiles || parishFiles.length === 0) {
        return <ItalicFM id="general.noData" />;
    } else {
        return fileType === ParishProfileFileType.File ? (
            <Card.Group itemsPerRow={1} stackable>
                {parishFiles.map((file: string, index: number) => (
                    <FileCard
                        editMode={editMode}
                        file={API_URL + `/parishes/${parish.ID}/files/${file}`}
                        fileName={file}
                        handleDeleteFile={handleDeleteFile}
                        index={index}
                        key={index}
                    />
                ))}
            </Card.Group>
        ) : (
            <Card.Group doubling itemsPerRow={2} stackable>
                {parishFiles.map((picture: string, index: number) => (
                    <PictureCard
                        editMode={editMode}
                        handleDeletePicture={handleDeletePicture}
                        index={index}
                        key={index}
                        picture={API_URL + `/parishes/${parish.ID}/pictures/${picture}`}
                    />
                ))}
            </Card.Group>
        );
    }
};

export default ParishProfileFileList;

import { LatLngExpression } from "leaflet";
import React from "react";
import { useIntl } from "react-intl";
import { LayersControl, Map, Marker, TileLayer, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import IMainPlacement from "../../../../data/priest/MainPlacement";
import IPlacement from "../../../../data/priest/Placement";
import IPriestProfileData from "../../../../data/priest/PriestProfileData";
import redMarker from "../../../../util/mapMarkers/redMarker";
import yellowMarker from "../../../../util/mapMarkers/yellowMarker";
import ErrorMsg from "../../../ErrorMsg";
import ProfilePlaceholder from "../../../ProfilePlaceholder";
import AssistantPriestPlaceMarker from "./AssistantPriestPlaceMarker";
import MainPriestPlaceMarker from "./MainPriestPlaceMarker";
import PriestLifePathMapLegend from "./PriestLifePathMapLegend";
import PriestLifePathPolyline from "./PriestLifePathPolyline";

interface IPriestLifePathOnTheMapContainerProps {
    priest: IPriestProfileData;
    loading: boolean;
    showError: boolean;
}

const PriestLifePathOnTheMapContainer: React.FC<IPriestLifePathOnTheMapContainerProps> = (props: IPriestLifePathOnTheMapContainerProps) => {
    const { priest, loading, showError } = props;
    const { formatMessage: fm } = useIntl();

    if (showError) {
        return <ErrorMsg errorMessage={"error.connection"} />;
    } else if (loading || !priest) {
        return <ProfilePlaceholder />;
    } else {
        return (
            <>
                <PriestLifePathMapLegend
                    assistantPriestPlaceNr={priest.assistantPriestPlaces?.length}
                    mainPriestPlaceNr={priest.mainPriestPlaces?.length}
                />
                <Map center={{ lat: 46.769, lng: 23.589 } as LatLngExpression} className="markercluster-map" zoom={7}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name={fm({ id: "mapType.default" })}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name={fm({ id: "mapType.monochrome" })}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.Overlay checked name={fm({ id: "profile.mainPriestPlace" })}>
                            <MarkerClusterGroup maxClusterRadius={15} removeOutsideVisibleBounds showCoverageOnHover={false}>
                                {/* Main priest place marker(s) on the map */}
                                {priest.mainPriestPlaces?.map(
                                    (placement: IMainPlacement) =>
                                        placement.coordinates.lat && placement.coordinates.lng && <MainPriestPlaceMarker placement={placement} />
                                )}
                            </MarkerClusterGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name={fm({ id: "profile.assistantPriestPlace" })}>
                            <MarkerClusterGroup maxClusterRadius={15} removeOutsideVisibleBounds showCoverageOnHover={false}>
                                {/* Assistant priest marker on the map */}
                                {priest.assistantPriestPlaces?.map(
                                    (placement: IPlacement) =>
                                        placement.coordinates.lat && placement.coordinates.lng && <AssistantPriestPlaceMarker placement={placement} />
                                )}
                            </MarkerClusterGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name={fm({ id: "profile.birthPlace" })}>
                            <MarkerClusterGroup maxClusterRadius={15} removeOutsideVisibleBounds showCoverageOnHover={false}>
                                {priest.birthLocation.coordinates.lat && priest.birthLocation.coordinates.lng && (
                                    <Marker icon={redMarker} position={[priest.birthLocation.coordinates.lat, priest.birthLocation.coordinates.lng]}>
                                        <Tooltip>{priest.birthLocation.placeName}</Tooltip>
                                    </Marker>
                                )}
                            </MarkerClusterGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name={fm({ id: "profile.consecrationPlace" })}>
                            <MarkerClusterGroup maxClusterRadius={15} removeOutsideVisibleBounds showCoverageOnHover={false}>
                                {priest.consecration.location.coordinates.lat && priest.consecration.location.coordinates.lng && (
                                    <Marker
                                        icon={yellowMarker}
                                        position={[priest.consecration.location.coordinates.lat, priest.consecration.location.coordinates.lng]}
                                    >
                                        <Tooltip>{priest.consecration.location.placeName}</Tooltip>
                                    </Marker>
                                )}
                            </MarkerClusterGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name={fm({ id: "mapProperty.lifePath" })}>
                            <PriestLifePathPolyline priest={priest} />
                        </LayersControl.Overlay>
                    </LayersControl>
                </Map>
            </>
        );
    }
};

export default PriestLifePathOnTheMapContainer;

interface INewPasswordData {
    id: string;
    password: string;
    confirmPassword?: string;
}

export const NewPasswordData: INewPasswordData = {
    id: "",
    password: "",
    confirmPassword: ""
};

export default INewPasswordData;

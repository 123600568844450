import React, { useEffect, useState } from "react";
import { Button, Divider, Header, Icon } from "semantic-ui-react";

import IEgyhazmegyeData from "../../../data/parish/EgyhazmegyeData";
import IParishGeneralData from "../../../data/parish/ParishGeneralData";
import { NewParishPastName } from "../../../data/parish/ParishPastName";
import IParishPlacement, { NewParishPlacement } from "../../../data/parish/ParishPlacement";
import IPriestMin from "../../../data/priest/PriestMin";
import doPut from "../../../requests/doPut";
import "../../../static/priestProfile.css";
import dateIntoString from "../../../util/dateConverts/dateIntoString";
import BoldFM from "../../../util/styledMessages/BoldFM";
import ItalicFM from "../../../util/styledMessages/ItalicFM";
import ErrorMsg from "../../ErrorMsg";
import NotesTextAreaContainer from "../../NotesTextAreaContainer";
import ProfilePlaceholder from "../../ProfilePlaceholder";
import ParishPastNameList from "../ParishPastNameList";
import ParishCoordinateContainer from "./ParishCoordinateContainer";
import ParishEgyhazmegyeContainer from "./ParishEgyhazmegyeContainer";
import ParishGeneralDataRowContainer from "./ParishGeneralDataRowContainer";
import ParishGeneralPlacementList from "./ParishGeneralPlacementList";

interface IParishGeneralDataContainerProps {
    parish: IParishGeneralData | undefined;
    loading: boolean;
    showError: boolean;
    editMode: boolean;
    setParish(newParish: IParishGeneralData): void;
    egyhazmegye?: IEgyhazmegyeData;
}

const ParishGeneralDataContainer: React.FC<IParishGeneralDataContainerProps> = (props: IParishGeneralDataContainerProps) => {
    const { parish, setParish, loading, showError, editMode, egyhazmegye } = props;

    const handleNewPastName = () => {
        if (parish) {
            if (parish.pastNames) {
                parish.pastNames.splice(0, 0, { ...NewParishPastName });
                setParish({ ...parish, pastNames: parish.pastNames });
            } else {
                setParish({ ...parish, pastNames: [{ ...NewParishPastName }] });
            }
        }
    };

    const comparePlacementDates = (placement1: IParishPlacement, placement2: IParishPlacement) => {
        return dateIntoString(placement1.startDate) > dateIntoString(placement2.startDate) ? 1 : -1;
    };

    const [mainPlacements, setMainPlacements] = useState<IParishPlacement[]>([]);
    const [assistantPlacements, setAssistantPlacements] = useState<IParishPlacement[]>([]);

    useEffect(() => {
        if (parish?.mainPriests) {
            setMainPlacements([...parish.mainPriests].sort(comparePlacementDates));
        }
    }, [parish?.mainPriests]);

    useEffect(() => {
        if (parish?.assistantPriests) {
            setAssistantPlacements([...parish.assistantPriests].sort(comparePlacementDates));
        }
    }, [parish?.assistantPriests]);

    if (showError) {
        return <ErrorMsg errorMessage={"error.connection"} />;
    } else if (loading || !parish) {
        return <ProfilePlaceholder />;
    } else {
        const handleChangesOnParish = (labelName: string, value: any) => {
            setParish({ ...parish, [labelName]: value });
        };

        const handleCoordinateChangesOnParish = (labelName: string, value: any) => {
            const newCoords = { ...parish.coordinates, [labelName]: value };
            setParish({ ...parish, coordinates: newCoords });
        };

        const handleEgyhazmegyeChangeOnParish = (value: string) => {
            setParish({ ...parish, egyhazmegye: value });
        };

        const handlePlacementChangeOnParish = (
            priest: IPriestMin,
            type: string,
            startYear: string,
            startMonth: string,
            startDay: string,
            endYear: string,
            endMonth: string,
            endDay: string
        ) => {
            const newPlacement: IParishPlacement = NewParishPlacement;
            newPlacement.priestID = priest.ID;
            newPlacement.priestName = priest.name;
            newPlacement.ID = parish.ID;

            if (type == "main") {
                doPut(
                    "/priestsLocation?priestID=" +
                        priest.ID +
                        "&parishID=" +
                        parish.ID +
                        "&placementType=main" +
                        "&startYear=" +
                        startYear +
                        "&startMonth=" +
                        startMonth +
                        "&startDay=" +
                        startDay +
                        "&endYear=" +
                        endYear +
                        "&endMonth=" +
                        endMonth +
                        "&endDay=" +
                        endDay,
                    {}
                );
            }

            if (type == "assistant") {
                doPut(
                    "/priestsLocation?priestID=" +
                        priest.ID +
                        "&parishID=" +
                        parish.ID +
                        "&placementType=assistant" +
                        "&startYear=" +
                        startYear +
                        "&startMonth=" +
                        startMonth +
                        "&startDay=" +
                        startDay +
                        "&endYear=" +
                        endYear +
                        "&endMonth=" +
                        endMonth +
                        "&endDay=" +
                        endDay,
                    {}
                );
            }
        };

        return (
            <>
                <Header as="h2">
                    <ItalicFM id="profile.generalData" />
                </Header>
                {egyhazmegye && (
                    <ParishEgyhazmegyeContainer editMode={editMode} egyhazmegye={egyhazmegye} setEgyhazmegye={handleEgyhazmegyeChangeOnParish} />
                )}
                <ParishGeneralDataRowContainer data={parish.name} editMode={editMode} labelName="name" setParish={handleChangesOnParish} />
                <ParishGeneralDataRowContainer data={parish.cityName} editMode={editMode} labelName="cityName" setParish={handleChangesOnParish} />
                <ParishCoordinateContainer
                    data={parish.coordinates.lat || 0}
                    editMode={editMode}
                    labelName="lat"
                    setParish={handleCoordinateChangesOnParish}
                />
                <ParishCoordinateContainer
                    data={parish.coordinates.lng || 0}
                    editMode={editMode}
                    labelName="lng"
                    setParish={handleCoordinateChangesOnParish}
                />
                <Divider />
                <ParishGeneralPlacementList
                    editmode={editMode}
                    headerKey="label.priest.mainPlacements"
                    placementType="main"
                    placements={mainPlacements}
                    setParish={handlePlacementChangeOnParish}
                />
                <Divider />
                <ParishGeneralPlacementList
                    editmode={editMode}
                    headerKey="label.priest.assistantPlacements"
                    placementType="assistant"
                    placements={assistantPlacements}
                    setParish={handlePlacementChangeOnParish}
                />

                <Divider />

                <Header as="h2">
                    <ItalicFM id="label.parish.history" />
                </Header>
                <NotesTextAreaContainer
                    data={parish.history}
                    editMode={editMode}
                    labelName="history"
                    setData={handleChangesOnParish}
                    textAreaSize={200}
                />

                <Divider />

                <Header as="h2">
                    <ItalicFM id="label.parish.names" />
                </Header>
                {editMode && (
                    <Button
                        basic
                        circular
                        color="violet"
                        icon
                        id="addNew"
                        labelPosition="left"
                        onClick={handleNewPastName}
                        style={{ marginBottom: "2em" }}
                    >
                        <Icon color="violet" name="add" size="large" />
                        <BoldFM id="profile.addNewPastName" />
                    </Button>
                )}
                <ParishPastNameList editMode={editMode} parish={parish} setParish={setParish} />
            </>
        );
    }
};

export default ParishGeneralDataContainer;

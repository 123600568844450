import React, { useState } from "react";
import { FormattedMessage as FM, useIntl } from "react-intl";
import { Button, Confirm, Form, Grid, Header, Icon, Message, Popup } from "semantic-ui-react";

import IPriestProfileData from "../../data/priest/PriestProfileData";
import BoldFM from "../../util/styledMessages/BoldFM";
import ProfilePlaceholder from "../ProfilePlaceholder";
import SuggestionModal from "../util/suggestions/SuggestionModal";
import PriestPDFPreview from "./pdf-print/PriestPDFPreview";

interface IPriestProfileHeaderProps {
    priest: IPriestProfileData;
    editMode: boolean;
    isAdmin: boolean;
    handleEditModeChange: (e: any, { value }: any) => void;
    setPriest(newPriest: IPriestProfileData): void;
    asyncDelete(url: string): void;
}

const PriestProfileHeader: React.FC<IPriestProfileHeaderProps> = (props: IPriestProfileHeaderProps) => {
    const { priest, editMode, isAdmin, setPriest, handleEditModeChange, asyncDelete } = props;
    const { formatMessage: fm } = useIntl();

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const handleDeleteSubmit = () => {
        setDeleteConfirmOpen(false);
        asyncDelete("/priest/" + priest.ID);
    };

    const handleDeleteConfirmOpen = (_: any) => {
        setDeleteConfirmOpen(true);
    };

    const toggleVerified = () => setPriest({ ...priest, verified: !priest.verified });

    const [suggestionMessage, setSuggestionMessage] = useState<string>();

    if (!priest) {
        return <ProfilePlaceholder />;
    } else {
        return (
            <>
                <Confirm
                    cancelButton={fm({ id: "general.cancel" })}
                    confirmButton={fm({ id: "confirm.delete.confirm" })}
                    content={fm({ id: "confirm.delete.message.priest" })}
                    onCancel={() => setDeleteConfirmOpen(false)}
                    onConfirm={handleDeleteSubmit}
                    open={deleteConfirmOpen}
                />

                <Grid columns={16} relaxed stackable>
                    <Grid.Row textAlign="center" verticalAlign="middle">
                        <Grid.Column />
                        <Grid.Column width={editMode ? 10 : 7}>
                            <Header as="h1">
                                {priest.name}
                                <span style={{ float: "right" }}>
                                    {editMode && (
                                        <Form.Checkbox
                                            checked={priest.verified}
                                            label={fm({ id: "profile.verified.label" })}
                                            name="verified"
                                            onChange={toggleVerified}
                                            toggle
                                        />
                                    )}
                                    {!editMode &&
                                        (priest.verified ? (
                                            <Popup
                                                content={fm({ id: "profile.verified.isVerifiedPopup" })}
                                                trigger={<Icon color="green" fitted name="check circle outline" />}
                                            />
                                        ) : (
                                            <Popup
                                                content={fm({ id: "profile.verified.notVerifiedPopup" })}
                                                trigger={<Icon color="grey" fitted name="question circle outline" />}
                                            />
                                        ))}
                                </span>
                            </Header>
                        </Grid.Column>
                        <Grid.Column />
                        {!editMode && isAdmin && (
                            <Grid.Column width={4}>
                                <>
                                    <Button
                                        color="violet"
                                        icon
                                        id="editModeButton"
                                        inverted
                                        labelPosition="left"
                                        onClick={handleEditModeChange}
                                        size="large"
                                        style={{ marginBottom: "1em", fontFamily: "monospace" }}
                                        value
                                    >
                                        <Icon name="edit" />
                                        <FM id="profile.edit" />
                                    </Button>
                                    <br />
                                    <Button
                                        color="red"
                                        icon
                                        id="removeButton"
                                        inverted
                                        labelPosition="left"
                                        onClick={handleDeleteConfirmOpen}
                                        size="large"
                                        style={{ fontFamily: "monospace" }}
                                        value
                                    >
                                        <Icon name="trash" />
                                        <FM id="profile.delete" />
                                    </Button>
                                </>
                            </Grid.Column>
                        )}
                        <Grid.Column width={3}>
                            {!editMode && !isAdmin && (
                                <SuggestionModal setSuggestionMessage={setSuggestionMessage} targetId={priest.ID} targetType="priest" />
                            )}
                            {!editMode && <PriestPDFPreview priest={priest} />}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {suggestionMessage !== undefined && (
                    <Message
                        negative={suggestionMessage === "error.request" || suggestionMessage === "error.server"}
                        positive={suggestionMessage === "suggestion.sent"}
                    >
                        <BoldFM id={suggestionMessage} />
                    </Message>
                )}
            </>
        );
    }
};

export default PriestProfileHeader;

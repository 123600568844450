import { FormattedMessage as FM } from "react-intl";
import { Dropdown } from "semantic-ui-react";

import Religion from "../../data/enums/Religion";

const religionOptions = [
    {
        key: Religion.REFORMED,
        text: <FM id="profile.religion.reformed" />,
        value: Religion.REFORMED
    },
    {
        key: Religion.UNITARIAN,
        text: <FM id="profile.religion.unitarian" />,
        value: Religion.UNITARIAN
    },
    {
        key: Religion.LUTHERAN,
        text: <FM id="profile.religion.lutheran" />,
        value: Religion.LUTHERAN
    },
    {
        key: Religion.SAXONLUTHERAN,
        text: <FM id="profile.religion.saxonlutheran" />,
        value: Religion.SAXONLUTHERAN
    },
    {
        key: Religion.CATHOLICISM,
        text: <FM id="profile.religion.catholicism" />,
        value: Religion.CATHOLICISM
    },
    {
        key: Religion.ORTHODOX,
        text: <FM id="profile.religion.orthodox" />,
        value: Religion.ORTHODOX
    },
    {
        key: "unknown",
        text: <FM id="profile.religion.unknown" />,
        value: "unknown"
    }
];

const allReligionOptions = [
    {
        key: "all",
        text: <FM id="label.all" />,
        value: "all"
    },
    ...religionOptions
];

interface ReligionDropdownProps {
    defaultValue: Religion | "all" | "";
    displayOptionForAll: boolean;
    setValue(_event: any, data: { [key: string]: Religion | "all" }): void;
}

const ReligionDropdown: React.FC<ReligionDropdownProps> = (props: ReligionDropdownProps) => {
    const { defaultValue, displayOptionForAll, setValue } = props;

    return (
        <Dropdown
            defaultValue={defaultValue}
            name="religion"
            onChange={setValue}
            options={displayOptionForAll ? allReligionOptions : religionOptions}
            selection
        />
    );
};

export default ReligionDropdown;

import React from "react";
import { FormattedMessage as FM, useIntl } from "react-intl";
import { Dropdown, Form, Grid, Header, Icon } from "semantic-ui-react";

import IParishFilters from "../../data/parish/ParishFilters";
import BoldFM from "../../util/styledMessages/BoldFM";

interface ISearchParishesControllerProps {
    filters: IParishFilters;
    isListView: boolean;
    setFilters(newFilters: object): void;
    toggleMap(): void;
    resetPagination(): void;
}

const SearchParishesController: React.FC<ISearchParishesControllerProps> = (props: ISearchParishesControllerProps) => {
    const { filters, isListView, setFilters, toggleMap, resetPagination } = props;
    const { formatMessage: fm } = useIntl();

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
        resetPagination();
    };

    const verifiedOptions = [
        {
            key: "all",
            text: <FM id="label.all" />,
            value: ""
        },
        {
            key: "verified",
            text: <FM id="profile.verified.isVerifiedPopupShort" />,
            value: "true",
            label: <Icon color="green" name="check circle outline" />
        },
        {
            key: "unverified",
            text: <FM id="profile.verified.notVerifiedPopupShort" />,
            value: "false",
            label: <Icon color="grey" name="question circle outline" />
        }
    ];

    const handleVerifiedChange = (value: string) => {
        if (value === "all") {
            value = "";
        }
        setFilters({
            ...filters,
            ["verifiedProfile"]: value
        });
        resetPagination();
    };

    return (
        <Form>
            <Header as="h1" className="controllerHeader">
                <FM id="search.parishes" />
            </Header>
            <Grid>
                <Grid.Row columns={2} style={{ marginBottom: "1.5em", marginTop: "1.5em" }} unstackable>
                    <Grid.Column textAlign="right">
                        <BoldFM id="label.listView" />
                    </Grid.Column>
                    <Grid.Column textAlign="left">
                        <Form.Checkbox checked={isListView} id="listView" name="toggleListView" onChange={toggleMap} toggle />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Form.Input id="name" label={fm({ id: "label.name" })} name="parishName" onChange={handleChange} value={filters.parishName} />
            <Form.Input id="city" label={fm({ id: "label.parish.cityName" })} name="cityName" onChange={handleChange} value={filters.cityName} />
            <Form.Input
                id="fullTextSearch"
                label={fm({ id: "label.fullTextSearch" })}
                name="fullTextSearch"
                onChange={handleChange}
                style={{ marginBottom: "-5em" }}
                value={filters.fullTextSearch}
            />
            <BoldFM id="label.priest.fullTextSearch" style={{ fontSize: "10px" }} /> <br /> <br />
            <Form.Input
                label={fm({ id: "search.minSize" })}
                name="minSize"
                onChange={handleChange}
                type="number"
                value={filters.minSize ? Number(filters.minSize) : undefined}
            />
            <BoldFM id="label.priest.verifiedProfile" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "7em" }} unstackable>
                <Form.Field width={13}>
                    <Dropdown
                        defaultValue={filters.verifiedProfile === "" ? "all" : filters.verifiedProfile}
                        onChange={(_, data) => {
                            handleVerifiedChange(data.value as string);
                        }}
                        options={verifiedOptions}
                        selection
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    );
};

export default SearchParishesController;

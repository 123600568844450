import React from "react";
import { Grid } from "semantic-ui-react";

import IPriestProfileData from "../../../data/priest/PriestProfileData";
import "../../../static/priestProfile.css";
import checkUncertain from "../../../util/uncertainCheck/checkUncertain";
import ErrorMsg from "../../ErrorMsg";
import ProfilePlaceholder from "../../ProfilePlaceholder";
import PriestGeneralDataReligionRow from "./PriestGeneralDataReligionRow";
import PriestGeneralDataRowContainer from "./PriestGeneralDataRowContainer";
import PriestGeneralDataRowContainerForConsecration from "./PriestGeneralDataRowContainerForConsecration";
import PriestGeneralDataRowContainerForDate from "./PriestGeneralDataRowContainerForDate";
import PriestGeneralDataStudyStatusRow from "./PriestGeneralDataStudyStatusRow";

interface IPriestGeneralDataContainerProps {
    priest: IPriestProfileData | undefined;
    loading: boolean;
    showError: boolean;
    editMode: boolean;
    setPriest(newPriest: IPriestProfileData): void;
}

const PriestGeneralDataContainer: React.FC<IPriestGeneralDataContainerProps> = (props: IPriestGeneralDataContainerProps) => {
    const { priest, setPriest, loading, showError, editMode } = props;

    if (showError) {
        return <ErrorMsg errorMessage={"error.connection"} />;
    } else if (loading || !priest) {
        return <ProfilePlaceholder />;
    } else {
        const handleChangesOnPriest = (labelName: string, value: any) => {
            // Uncertain fields were not initialized
            if (labelName === "uncertainFields" && !priest.uncertainFields) {
                setPriest({ ...priest, [labelName]: [value] });
                return;
            }
            // Uncertain fields not changed, general data was edited
            if (labelName !== "uncertainFields") {
                setPriest({ ...priest, [labelName]: value });
                return;
            }
            // Removing field from uncertain fields
            if (priest.uncertainFields!.includes(value)) {
                const newUncertainFields = priest.uncertainFields!.filter((field) => field !== value);
                setPriest({ ...priest, [labelName]: newUncertainFields });
                return;
            }
            // Adding field to uncertain fields
            // Note: It is very important to create a copy from the original array (with `[...originalArray ]`)
            // of the priest object, otherwise we might accidentally make changes on the original object
            const newUncertainFields = [...priest.uncertainFields!];
            newUncertainFields.push(value);
            setPriest({ ...priest, [labelName]: newUncertainFields });
        };

        return (
            <Grid columns={2}>
                <PriestGeneralDataRowContainer
                    data={priest.name}
                    editMode={editMode}
                    labelName="name"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "name")}
                />
                <PriestGeneralDataRowContainerForDate
                    data={priest.birthDate}
                    editMode={editMode}
                    isGrey
                    labelName="birthDate"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "birthDate")}
                />
                <PriestGeneralDataRowContainer
                    data={priest.birthCountry}
                    editMode={editMode}
                    labelName="birthCountry"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "birthCountry")}
                />
                <PriestGeneralDataRowContainer
                    data={priest.birthCounty}
                    editMode={editMode}
                    isGrey
                    labelName="birthCounty"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "birthCounty")}
                />
                <PriestGeneralDataRowContainer
                    data={priest.birthTown}
                    editMode={editMode}
                    labelName="birthTown"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "birthTown")}
                />
                <PriestGeneralDataRowContainerForDate
                    data={priest.subscriptionDate}
                    editMode={editMode}
                    isGrey
                    labelName="subscriptionDate"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "subscriptionDate")}
                />
                <PriestGeneralDataRowContainerForDate
                    data={priest.graduationDate}
                    editMode={editMode}
                    labelName="graduationDate"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "graduationDate")}
                />
                <PriestGeneralDataRowContainerForConsecration
                    consecrationData={priest.consecration}
                    editMode={editMode}
                    priest={priest}
                    setPriest={setPriest}
                    uncertainDate={checkUncertain(priest, "consecrationDate")}
                    uncertainPlace={checkUncertain(priest, "consecrationPlace")}
                />
                <PriestGeneralDataRowContainer
                    data={priest.diocese}
                    editMode={editMode}
                    isGrey
                    labelName="diocese"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "diocese")}
                />
                <PriestGeneralDataRowContainerForDate
                    data={priest.resigned}
                    editMode={editMode}
                    labelName="resigned"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "resigned")}
                />
                <PriestGeneralDataRowContainerForDate
                    data={priest.retirementDate}
                    editMode={editMode}
                    isGrey
                    labelName="retirementDate"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "retirementDate")}
                />
                <PriestGeneralDataRowContainerForDate
                    data={priest.deathDate}
                    editMode={editMode}
                    labelName="deathDate"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "deathDate")}
                />
                <PriestGeneralDataStudyStatusRow
                    data={priest.studyStatus}
                    editMode={editMode}
                    labelName="studyStatus"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "studyStatus")}
                />
                <PriestGeneralDataReligionRow
                    data={priest.religion}
                    editMode={editMode}
                    isGrey
                    labelName="religion"
                    setPriest={handleChangesOnPriest}
                    uncertain={checkUncertain(priest, "religion")}
                />
            </Grid>
        );
    }
};

export default PriestGeneralDataContainer;

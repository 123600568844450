import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Header, Label, SemanticCOLORS } from "semantic-ui-react";

interface IPriestLifePathMapLegendProps {
    assistantPriestPlaceNr: number;
    mainPriestPlaceNr: number;
}

const MapLegendItem: React.FC<{ children: JSX.Element[] }> = ({ children }: { children: JSX.Element[] }) => (
    <span style={{ display: "flex", gap: 5, alignItems: "center" }}>{children}</span>
);

const MapLegendCircle: React.FC<{ color: SemanticCOLORS }> = ({ color }: { color: SemanticCOLORS }) => (
    <Label circular color={color} style={{ fontSize: 6 }} />
);

const PriestLifePathMapLegend: React.FC<IPriestLifePathMapLegendProps> = (props: IPriestLifePathMapLegendProps) => {
    const { assistantPriestPlaceNr, mainPriestPlaceNr } = props;

    return (
        <Header as="h5" style={{ fontWeight: "normal", display: "flex", flexWrap: "wrap", gap: 10, justifyContent: "center" }}>
            <MapLegendItem>
                <MapLegendCircle color="red" />
                <FM id="profile.birthPlace" />
            </MapLegendItem>
            <MapLegendItem>
                <MapLegendCircle color="yellow" />
                <FM id="profile.consecrationPlace" />
            </MapLegendItem>
            <MapLegendItem>
                <MapLegendCircle color="green" />
                <FM id="label.priest.assistantPlacements" />
                <span>
                    {"("}
                    {assistantPriestPlaceNr || 0}
                    {")"}
                </span>
            </MapLegendItem>
            <MapLegendItem>
                <MapLegendCircle color="blue" />
                <FM id="label.priest.mainPlacements" />
                <span>
                    {"("}
                    {mainPriestPlaceNr || 0}
                    {")"}
                </span>
            </MapLegendItem>
        </Header>
    );
};

export default PriestLifePathMapLegend;

import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Button } from "semantic-ui-react";

import IPriestProfileData from "../../data/priest/PriestProfileData";
import { mapToExportedPriestData } from "../../util/csvExportUtils/exportMapperFunctions";
import { toCSV } from "../../util/csvExportUtils/exportToCSV";

interface IExportPriestButtonProps {
    priests: IPriestProfileData[];
}

const ExportPriestButton: React.FC<IExportPriestButtonProps> = (props: IExportPriestButtonProps) => {
    const { priests } = props;

    const exportasCSV = async () => {
        if (priests) {
            let dataRow: object;
            const dataRows = [];
            for (let i = 0; i < priests?.length; i++) {
                dataRow = mapToExportedPriestData(priests[i]);
                dataRows.push(dataRow);
            }
            toCSV().exportAsCSVFile(dataRows).saveAsCSVFile("exportedPriestData");
        }
    };

    return (
        <Button color="violet" fluid onClick={exportasCSV} size="large">
            <FM id="profile.excelExport" />
        </Button>
    );
};

export default ExportPriestButton;

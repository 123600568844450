import leaflet from "leaflet";

import Icon from "../../static/images/markers/marker-icon-2x-green.png";
import shadow from "../../static/images/markers/marker-shadow.png";

const greenMarker = new leaflet.Icon({
    iconUrl: Icon,
    shadowUrl: shadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

export default greenMarker;

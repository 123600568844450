import IPriestProfileData from "../../data/priest/PriestProfileData";

const checkUncertain = (priest: IPriestProfileData, labelName: string): boolean => {
    if (!priest.uncertainFields) {
        return false;
    }
    return priest.uncertainFields.includes(labelName);
};

export default checkUncertain;

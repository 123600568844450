import React, { useEffect, useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";

import ICreateSuggestion, { NewCreateSuggestion } from "../../../data/suggestion/CreateSuggestion";
import ICreateSuggestionResponse from "../../../requests/response/CreateSuggestionResponse";
import usePost from "../../../requests/usePost";
import useSession from "../../../util/useSession/useSession";

interface ISuggestionModalProps {
    targetType: string;
    targetId: number;
    setSuggestionMessage(url: string): void;
}

const SuggestionModal: React.FC<ISuggestionModalProps> = (props: ISuggestionModalProps) => {
    const { targetType, targetId, setSuggestionMessage } = props;

    const [open, setOpen] = useState(false);

    const [suggestion, setSuggestion] = useState({ ...NewCreateSuggestion, targetType, targetId });

    const { response, asyncPost } = usePost<ICreateSuggestion, ICreateSuggestionResponse>("/suggestion", suggestion);

    const user = useSession().user;

    useEffect(() => {
        if (user?.name && user.email) {
            setSuggestion((s) => ({ ...s, proposerName: user.name, proposerEmail: user.email }));
        }
        if (targetType && targetId) {
            setSuggestion((s) => ({ ...s, targetType, targetId }));
        }
    }, [targetId, targetType, user]);

    const handleChange = (event: any) => {
        const { value } = event.target;
        setSuggestion({ ...suggestion, text: value as string });
    };

    const handleSubmit = () => {
        asyncPost();
        if (response && response.label.startsWith("error.request")) {
            setSuggestionMessage("error.request");
        } else if (response && response.label.startsWith("error.server")) {
            setSuggestionMessage("error.server");
        } else {
            setSuggestionMessage("suggestion.sent");
        }
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Button color="violet" icon inverted labelPosition="left" size="large" style={{ marginBottom: "1em", fontFamily: "monospace" }} value>
                    <Icon name="lightbulb" />
                    <FM id="suggestion.button" />
                </Button>
            }
        >
            <Modal.Header>
                <FM id="suggestion.title" />
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <FM id="suggestion.description" />
                </Modal.Description>
                <Form>
                    <TextArea name="suggestionText" onChange={handleChange} style={{ minHeight: "15em" }} type="text" />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="grey" onClick={() => setOpen(false)} size="large">
                    <FM id="general.cancel" />
                </Button>
                <Button
                    color="violet"
                    onClick={() => {
                        setOpen(false);
                        handleSubmit();
                    }}
                    size="large"
                    type="submit"
                >
                    <FM id="suggestion.submitButton" />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SuggestionModal;

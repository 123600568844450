import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Divider } from "semantic-ui-react";

interface ISectionDividerProps {
    titleId: string;
    marginTop?: string;
}
const SectionDivider: React.FC<ISectionDividerProps> = (props: ISectionDividerProps) => {
    const { titleId, marginTop } = props;
    return (
        <Divider horizontal style={{ marginTop: marginTop ?? "100px" }}>
            <FM id={titleId} />
        </Divider>
    );
};

export default SectionDivider;

interface ILanguageSkills {
    well: string;
    medium: string;
    less: string;
}

export const NewLanguageSkills: ILanguageSkills = {
    well: "",
    medium: "",
    less: ""
};

export default ILanguageSkills;

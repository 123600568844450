import { FormattedMessage as FM } from "react-intl";
import { Dropdown } from "semantic-ui-react";

import StudyStatus from "../../data/enums/StudyStatus";

const studyStatusOptions = [
    {
        key: StudyStatus.GRADUATED,
        text: <FM id="profile.studyStatus.graduated" />,
        value: StudyStatus.GRADUATED
    },
    {
        key: StudyStatus.DROPPED_OUT_DUE_TO_ILLNESS,
        text: <FM id="profile.studyStatus.droppedOutDueToIllness" />,
        value: StudyStatus.DROPPED_OUT_DUE_TO_ILLNESS
    },
    {
        key: StudyStatus.EXMATRICULATE,
        text: <FM id="profile.studyStatus.exmatriculate" />,
        value: StudyStatus.EXMATRICULATE
    },
    {
        key: StudyStatus.TRANSFERED,
        text: <FM id="profile.studyStatus.transfered" />,
        value: StudyStatus.TRANSFERED
    },
    {
        key: StudyStatus.TOOK_OUT_DOCUMENTS,
        text: <FM id="profile.studyStatus.tookOutDocuments" />,
        value: StudyStatus.TOOK_OUT_DOCUMENTS
    },
    {
        key: StudyStatus.DIED,
        text: <FM id="profile.studyStatus.died" />,
        value: StudyStatus.DIED
    },
    {
        key: "unknown",
        text: <FM id="profile.studyStatus.unknown" />,
        value: "unknown"
    }
];

const allStudyStatusOptions = [
    {
        key: "all",
        text: <FM id="label.all" />,
        value: "all"
    },
    ...studyStatusOptions
];

interface StudyStatusDropdownProps {
    defaultValue: StudyStatus | "all" | "";
    displayOptionForAll: boolean;
    setValue(_: any, data: { [key: string]: StudyStatus | "all" }): void;
}

const StudyStatusDropdown: React.FC<StudyStatusDropdownProps> = (props: StudyStatusDropdownProps) => {
    const { defaultValue, displayOptionForAll, setValue } = props;

    return (
        <Dropdown
            defaultValue={defaultValue}
            name="studyStatus"
            onChange={setValue}
            options={displayOptionForAll ? allStudyStatusOptions : studyStatusOptions}
            selection
        />
    );
};

export default StudyStatusDropdown;

import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Modal } from "semantic-ui-react";

import ISuggestion from "../../data/suggestion/Suggestion";
import SuggestionButtons from "./SuggestionButtons";

interface ISuggestionDetailsProp {
    suggestion: ISuggestion;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setResolved?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowError?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuggestionDetails: React.FC<ISuggestionDetailsProp> = (props: ISuggestionDetailsProp) => {
    const { suggestion, setOpen, setResolved, setShowError } = props;

    const open = true;

    return (
        <Modal onClose={() => setOpen(false)} open={open}>
            <Modal.Header>
                {`${suggestion.targetName} (`}
                <FM id={suggestion.targetType} />
                {")"}
            </Modal.Header>
            <Modal.Content>
                <FM id="suggestion.issuer" /> <b>{`${suggestion.proposerName} (${suggestion.proposerEmail})`}</b>
                <br />
                <FM id="suggestion.date" />
                <b>
                    {new Date(suggestion.creationDate).toLocaleString(localStorage.getItem("languageKey") || "hu", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                    })}
                </b>
                <Modal.Description style={{ whiteSpace: "pre-wrap", marginTop: "2em" }}>{suggestion.text}</Modal.Description>
            </Modal.Content>
            {!suggestion.accepted ? (
                <Modal.Actions>
                    <SuggestionButtons
                        setOpen={setOpen}
                        setResolved={setResolved}
                        setShowError={setShowError}
                        suggestionID={suggestion.ID}
                        targetID={suggestion.targetId}
                        targetType={suggestion.targetType}
                    />
                </Modal.Actions>
            ) : (
                <Modal.Actions>
                    <FM id="suggestion.acceptedBy" />: {suggestion.acceptedBy}
                </Modal.Actions>
            )}
        </Modal>
    );
};

export default SuggestionDetails;

enum StudyStatus {
    GRADUATED = "graduated",
    DROPPED_OUT_DUE_TO_ILLNESS = "droppedOutDueToIllness",
    EXMATRICULATE = "exmatriculate",
    TRANSFERED = "transfered",
    TOOK_OUT_DOCUMENTS = "tookOutDocuments",
    DIED = "died"
}

export default StudyStatus;

interface ICreateSuggestion {
    proposerName: string;
    proposerEmail: string;
    targetType: string;
    targetId: number;
    text: string;
}

export const NewCreateSuggestion: ICreateSuggestion = {
    proposerName: "",
    proposerEmail: "",
    targetType: "",
    targetId: 0,
    text: ""
};

export default ICreateSuggestion;

import _ from "lodash";
import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Container, Form, Grid } from "semantic-ui-react";

import Religion from "../../../data/enums/Religion";
import "../../../static/priestProfile.css";
import BoldFM from "../../../util/styledMessages/BoldFM";
import ItalicFM from "../../../util/styledMessages/ItalicFM";
import ReligionDropdown from "../ReligionDropdown";
import UncertainCheckbox from "../priest-data-uncertain-check/UncertainCheckbox";
import UncertainIcon from "../priest-data-uncertain-check/UncertainIcon";

interface IPriestGeneralDataReligionRowProps {
    labelName: string;
    data: Religion | "all" | "";
    isGrey?: boolean;
    editMode: boolean;
    setPriest(labelName: string, value: any): any;
    uncertain?: boolean;
}

const PriestGeneralDataReligionRow: React.FC<IPriestGeneralDataReligionRowProps> = (props: IPriestGeneralDataReligionRowProps) => {
    const { labelName, data, editMode, isGrey, setPriest, uncertain } = props;

    const handleReligionChange = (_: any, data: { value: Religion }) => {
        const value = data.value;
        setPriest(labelName, value);
    };

    const handleUncertainChange = () => {
        setPriest("uncertainFields", labelName);
    };

    return (
        <Grid.Row id={isGrey ? "grey" : ""}>
            <Grid.Column align="center" className="text">
                <BoldFM id={"profile." + labelName} showColon />
            </Grid.Column>
            <Grid.Column className="text" name={labelName}>
                {editMode ? (
                    <Form.Group inline style={{ display: "flex", alignItems: "center" }} unstackable>
                        <Form.Field width={13}>
                            <ReligionDropdown defaultValue={data} displayOptionForAll={false} setValue={handleReligionChange} />
                        </Form.Field>
                        {!_.isNil(uncertain) && !_.isNil(handleUncertainChange) && (
                            <UncertainCheckbox handleUncertainChange={handleUncertainChange} uncertain={uncertain} />
                        )}
                    </Form.Group>
                ) : data ? (
                    <Container>
                        <FM id={"profile.religion." + data} />
                        {uncertain && <UncertainIcon />}
                    </Container>
                ) : (
                    <ItalicFM id="general.noData" />
                )}
            </Grid.Column>
        </Grid.Row>
    );
};

export default PriestGeneralDataReligionRow;

import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { Button, Container, Header, Icon, Modal } from "semantic-ui-react";

import IPriestProfileData from "../../../data/priest/PriestProfileData";
import "../../../static/semanticUiOverwrites.css";
import SectionDivider from "../../util/SectionDivider";
import PriestDisciplinaryMattersContainer from "../priest-disciplinary-matters/PriestDisciplinaryMattersContainer";
import PriestFamilyContainer from "../priest-family/PriestFamilyContainer";
import PriestGeneralDataContainer from "../priest-general-data/PriestGeneralDataContainer";
import PriestOccupationContainer from "../priest-occupation/PriestOccupationContainer";
import PriestPlacementContainer from "../priest-placements/PriestPlacementContainer";
import PriestQualificationContainer from "../priest-qualification/PriestQualificationContainer";
import PriestReferencesContainer from "../priest-references/PriestReferencesContainer";
import PriestPDFModalActions from "./PriestPDFModalActions";

interface IPriestPDFPreviewProps {
    priest: IPriestProfileData;
}

const PriestPDFPreview: React.FC<IPriestPDFPreviewProps> = (props: IPriestPDFPreviewProps) => {
    const priest = props.priest;

    const [openPreview, setOpenPreview] = useState(false);

    const priestProfileProps = {
        priest: priest,
        loading: false,
        showError: false,
        editMode: false,
        setPriest: (_: IPriestProfileData): void => {
            return;
        }
    };

    const closePreview = () => {
        setOpenPreview(false);
    };

    return (
        <Modal
            onClose={closePreview}
            onOpen={() => setOpenPreview(true)}
            open={openPreview}
            style={{ padding: "25px 150px", backgroundColor: "grey", width: "70%" }}
            trigger={
                <Button color="purple" icon inverted labelPosition="left" size="large" style={{ fontFamily: "monospace" }}>
                    <Icon name="print" />
                    <FM id="profile.pdfExport" />
                </Button>
            }
        >
            <PriestPDFModalActions closePreview={closePreview} priestName={priest.name} />
            <Modal.Header>
                <FM id="profile.pdfExport.preview" />
            </Modal.Header>
            <Modal.Content>
                <Container style={{ padding: "10px 20px", border: "1px solid black" }}>
                    <Container id="pdfContent" style={{ backgroundColor: "#ffffff", padding: "25px 25px 25px 75px" }}>
                        <Header as="h1" textAlign="center">
                            {priest.name}
                        </Header>
                        <SectionDivider marginTop="60px" titleId="profile.generalData" />
                        <PriestGeneralDataContainer {...priestProfileProps} />

                        <SectionDivider titleId="menu.family" />
                        <PriestFamilyContainer {...priestProfileProps} />

                        <SectionDivider titleId="menu.qualification" />
                        <PriestQualificationContainer {...priestProfileProps} />

                        <SectionDivider titleId="menu.placements" />
                        <PriestPlacementContainer {...priestProfileProps} />

                        <SectionDivider titleId="menu.occupations" />
                        <PriestOccupationContainer {...priestProfileProps} />

                        <SectionDivider titleId="menu.disciplinaryMatters" />
                        <PriestDisciplinaryMattersContainer {...priestProfileProps} />

                        <SectionDivider titleId="menu.references" />
                        <PriestReferencesContainer {...priestProfileProps} />

                        <SectionDivider titleId="menu.notes" />
                        <Container style={{ whiteSpace: "pre-wrap" }}>{priest.note}</Container>
                    </Container>
                </Container>
            </Modal.Content>
            <PriestPDFModalActions closePreview={closePreview} priestName={priest.name} />
        </Modal>
    );
};

export default PriestPDFPreview;

import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";

import IEgyhazmegyeData from "../../data/parish/EgyhazmegyeData";
import useFetchData from "../../requests/useFetchData";

interface IEgyhazmegyeDropdownProps {
    defaultSelectionID?: string;
    setEgyhazmegye(value: any): any;
}

const EgyhazmegyeDropdown: React.FC<IEgyhazmegyeDropdownProps> = (props: IEgyhazmegyeDropdownProps) => {
    const { defaultSelectionID, setEgyhazmegye } = props;
    const { response, loading } = useFetchData<IEgyhazmegyeData[]>("/egyhazmegyek");
    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    const handleChange = (event: any, data: any) => {
        setEgyhazmegye(data.value);
    };

    useEffect(() => {
        if (response) {
            const newOptions = [];
            for (const egyhazmegye of response) {
                newOptions.push({
                    key: egyhazmegye.ID,
                    text: egyhazmegye.name,
                    value: egyhazmegye.ID
                });
            }
            setOptions(newOptions);
        } else {
            setOptions([]);
        }
    }, [response]);

    return (
            <Dropdown button defaultValue={defaultSelectionID} loading={loading} onChange={handleChange} options={options} simple />
    );
};

export default EgyhazmegyeDropdown;

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { Card, Container, Dropdown, Header, Icon, Pagination } from "semantic-ui-react";

import ISuggestion from "../../data/suggestion/Suggestion";
import useFetchData from "../../requests/useFetchData";
import CardPlaceholder from "../CardPlaceholder";
import ErrorMsg from "../ErrorMsg";
import SuggestionCard from "./SuggestionCard";

const PAGESIZE = 10;

interface ISearchSuggestionsContainerProps {
    suggestions: ISuggestion[] | undefined;
    loading: boolean;
    showError: boolean;
    currentPage: number;
    setCurrentPage(newPage: number): void;
    sort: string;
    setSort(newSort: string): void;
    resetPagination(): void;
    accepted?: string;
}

const SearchSuggestionContainer: React.FC<ISearchSuggestionsContainerProps> = (props: ISearchSuggestionsContainerProps) => {
    const { response } = useFetchData<ISuggestion[]>("/suggestions");

    const getActiveSuggestions = (response?: ISuggestion[]) => {
        if (!response) {
            return [];
        }
        const active: ISuggestion[] = [];
        for (const suggestion of response) {
            if (_.isNil(suggestion.accepted)) {
                active.push(suggestion);
            }
        }
        return active;
    };

    const { suggestions, loading, showError, currentPage, setCurrentPage, accepted, sort, setSort, resetPagination } = props;
    const [activeSuggestions, setActiveSuggestions] = useState(getActiveSuggestions(suggestions));
    const [displayedSuggestions, setDisplayedSuggestions] = useState<ISuggestion[]>([]);

    const sortOptions = ["date", "targetName", "proposer"]
        .map((value) => [
            {
                key: `${value}Desc`,
                text: (
                    <>
                        <FM id={`sort.${value}`} />
                        <Icon color={"violet"} name={"arrow down"} />
                    </>
                ),
                value: `${value}Desc`
            },
            {
                key: `${value}Asc`,
                text: (
                    <>
                        <FM id={`sort.${value}`} />
                        <Icon color={"violet"} name={"arrow up"} />
                    </>
                ),
                value: `${value}Asc`
            }
        ])
        .flat();

    const handleSort = (event: any, data: any) => {
        const { value } = data;
        setSort(value as string);
        resetPagination();
    };

    useEffect(() => {
        setActiveSuggestions(getActiveSuggestions(response));
    }, [response]);

    useEffect(() => {
        if (!suggestions) {
            setDisplayedSuggestions([]);
            return;
        }

        const sortedSuggestions = [...suggestions];

        switch (sort) {
            case "dateAsc":
                sortedSuggestions.sort((a, b) => (new Date(a.creationDate) > new Date(b.creationDate) ? 1 : -1));
                break;
            case "dateDesc":
                sortedSuggestions.sort((a, b) => (new Date(a.creationDate) > new Date(b.creationDate) ? -1 : 1));
                break;
            case "proposerNameAsc":
                sortedSuggestions.sort((a, b) => (a.proposerName > b.proposerName ? 1 : -1));
                break;
            case "proposerNameDesc":
                sortedSuggestions.sort((a, b) => (a.proposerName > b.proposerName ? -1 : 1));
                break;
            case "targetNameAsc":
                sortedSuggestions.sort((a, b) => (a.targetName > b.targetName ? 1 : -1));
                break;
            case "targetNameDesc":
                sortedSuggestions.sort((a, b) => (a.targetName > b.targetName ? -1 : 1));
                break;
        }
        setDisplayedSuggestions(sortedSuggestions);
    }, [sort, suggestions]);

    const handlePageChange = (_: any, { activePage }: any) => setCurrentPage(activePage);

    if (loading) {
        return <CardPlaceholder />;
    }

    if (showError) {
        return <ErrorMsg errorMessage={"error.connection"} />;
    }

    console.log(suggestions);

    if (activeSuggestions?.length === 0 && _.isNil(accepted)) {
        return (
            <>
                <CardPlaceholder />
                <Header as="h2">
                    <FM id="search.noActiveResult" />
                </Header>
            </>
        );
    } else if (!suggestions || suggestions?.length === 0 || suggestions?.filter((suggestion) => suggestion.accepted === accepted).length === 0) {
        return (
            <>
                <CardPlaceholder />
                <Header as="h2">
                    <FM id="search.noResult" />
                </Header>
            </>
        );
    } else {
        return (
            <>
                <Dropdown
                    defaultValue={sort}
                    name="sort"
                    onChange={handleSort}
                    options={sortOptions}
                    selection
                    style={{ marginLeft: "80%", marginBottom: "2em" }}
                />
                <Card.Group doubling itemsPerRow={1} stackable>
                    {displayedSuggestions?.slice((currentPage - 1) * PAGESIZE, currentPage * PAGESIZE).map((suggestion: ISuggestion) => {
                        if (suggestion.accepted === accepted) {
                            return <SuggestionCard key={suggestion.ID} suggestion={suggestion} />;
                        }
                    })}
                </Card.Group>
                <Container style={{ marginTop: "3em" }} textAlign="center">
                    <Pagination
                        activePage={currentPage}
                        onPageChange={handlePageChange}
                        totalPages={Math.ceil((activeSuggestions?.length || 0) / PAGESIZE)}
                    />
                </Container>
            </>
        );
    }
};

export default SearchSuggestionContainer;

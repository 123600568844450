import { LatLng } from "leaflet";
import React from "react";
import { Polyline } from "react-leaflet";

import IPlacement from "../../../../data/priest/Placement";
import IPriestProfileData from "../../../../data/priest/PriestProfileData";

interface IPriestLifePathPolylineProps {
    priest: IPriestProfileData;
}

function filterOutInvalidPlacements(placements: IPlacement[]) {
    if (!placements) {
        return [];
    }
    return placements.filter((placement: IPlacement) => placement.coordinates.lat !== undefined && placement.coordinates.lng !== undefined);
}

const PriestLifePathPolyline: React.FC<IPriestLifePathPolylineProps> = (props: IPriestLifePathPolylineProps) => {
    const { priest } = props;
    const placesWithCoordinates = [
        priest.birthLocation.coordinates.lat && priest.birthLocation.coordinates.lng
            ? new LatLng(priest.birthLocation.coordinates.lat, priest.birthLocation.coordinates.lng)
            : null,

        priest.consecration.location.coordinates.lat && priest.consecration.location.coordinates.lng
            ? new LatLng(priest.consecration.location.coordinates.lat, priest.consecration.location.coordinates.lng)
            : null
    ]

        .concat(
            filterOutInvalidPlacements(priest.mainPriestPlaces)
                .concat(filterOutInvalidPlacements(priest.assistantPriestPlaces))
                .sort((a, b) => (a.startDate <= b.startDate ? 1 : -1))
                .map((it) => new LatLng(it.coordinates.lat || 0, it.coordinates.lng || 0))
        )
        .filter((it) => !!it) as LatLng[];

    const polyline: L.LatLngExpression[] = placesWithCoordinates;

    return <Polyline color="lime" positions={polyline} />;
};

export default PriestLifePathPolyline;

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Container } from "semantic-ui-react";

import SearchSuggestionsController from "../components/search-suggestion/SearchSuggestionController";
import SearchSuggestionsContainer from "../components/search-suggestion/SearchSuggestionsContainer";
import ISuggestion from "../data/suggestion/Suggestion";
import ISuggestionFilter from "../data/suggestion/SuggestionFilters";
import useFetchData from "../requests/useFetchData";
import PageWrapperTwoColumns from "./PageWrapperTwoColumns";

const SearchSuggestionsPage: React.FC = () => {
    const params = new URLSearchParams(window.location.search);

    const initCurrentPage: number = Number(params.get("page")) || 1;
    const [currentPage, setCurrentPage] = useState(initCurrentPage);
    const resetPagination = () => {
        setCurrentPage(1);
    };

    const initProposer = params.get("proposer") || "";
    const initTargetType = params.get("targetType") || "";
    const initTargetName = params.get("targetName") || "";
    const initAccepted = params.get("accepted") || undefined;
    const initSuggestionFilters: ISuggestionFilter = {
        proposer: initProposer,
        targetType: initTargetType,
        targetName: initTargetName,
        accepted: initAccepted
    };
    const [filters, setFilters] = useState(initSuggestionFilters);

    const filterURL = Object.entries(filters).reduce((prev: string, entry: [string, string]) => {
        if (entry[1] === "" || entry[1] === undefined) return prev;
        return prev + entry[0] + "=" + encodeURIComponent(entry[1]) + "&";
    }, "");

    const [sort, setSort] = useState(params.get("sort") || "dateDesc");

    const { response, loading, showError } = useFetchData<ISuggestion[]>(`/suggestions?${filterURL}`);

    return (
        <>
            <Redirect to={`/suggestions/search?${filterURL}page=${currentPage}&sort=${sort}`} />
            <Container className={"suggestionPageWrapper"} fluid>
                <PageWrapperTwoColumns>
                    <SearchSuggestionsController filters={filters} resetPagination={resetPagination} setFilters={setFilters} />
                    <SearchSuggestionsContainer
                        accepted={filters.accepted}
                        currentPage={currentPage}
                        loading={loading}
                        resetPagination={resetPagination}
                        setCurrentPage={setCurrentPage}
                        setSort={setSort}
                        showError={showError}
                        sort={sort}
                        suggestions={response}
                    />
                </PageWrapperTwoColumns>
            </Container>
        </>
    );
};

export default SearchSuggestionsPage;

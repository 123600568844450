import IDate, { NewDate } from "../Date";

interface IChangedCareer {
    name: string;
    date: IDate;
}

export const NewChangedCareer: IChangedCareer = { name: "", date: NewDate };

export default IChangedCareer;

import React from "react";
import { Responsive, Segment } from "semantic-ui-react";

import "../static/priestProfile.css";
import EditModeControlLabelButtons from "./EditModeControlLabelButtons";

interface IEditModeControlLabelProps {
    text: string;
    onSave(): any;
    onRevertChanges(): any;
}

const EditModeControlLabel: React.FC<IEditModeControlLabelProps> = (props: IEditModeControlLabelProps) => {
    const { text, onSave, onRevertChanges } = props;

    return (
        <>
            {/* Computer view */}
            <Responsive as={Segment} id="editControlLabelComputer" minWidth={769} tertiary>
                <EditModeControlLabelButtons onRevertChanges={onRevertChanges} onSave={onSave} text={text} />
            </Responsive>

            {/* Mobile view */}
            <Responsive as={Segment} id="editControlLabelMobile" maxWidth={768} tertiary>
                <EditModeControlLabelButtons onRevertChanges={onRevertChanges} onSave={onSave} text={text} />
            </Responsive>
        </>
    );
};

export default EditModeControlLabel;

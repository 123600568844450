import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// eslint-disable-next-line
export const toCSV = () => {
    const format = "csv"; // csv|xlsx
    const xlsxLib = {
        buffer: "",
        exportAsCSVFile(json: any[]) {
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
            const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            this.buffer = XLSX.write(workbook, { bookType: format, type: "array" });
            return this;
        },
        saveAsCSVFile(fileName: string) {
            const data: Blob = new Blob([this.buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
            FileSaver.saveAs(data, fileName + "." + format);
            return this;
        }
    };
    return xlsxLib;
};

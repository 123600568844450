import React from "react";
import { Card, Header, List } from "semantic-ui-react";

import IParent from "../../../data/priest/Parent";
import "../../../static/priestProfile.css";
import CardRow from "../CardRow";
import CardRowForDate from "../CardRowForDate";
import CardDropdownReligion from "../CardRowForReligion";

interface IParentCardProps {
    parentData: IParent;
    editMode: boolean;
    label: string;
    handleChangeOnParent: (labelName: string, value: any) => void;
}

const ParentCard: React.FC<IParentCardProps> = (props: IParentCardProps) => {
    const { parentData, editMode, handleChangeOnParent, label } = props;

    const handleChange = (labelName: string, value: any) => {
        handleChangeOnParent(label, { ...parentData, [labelName]: value });
    };

    return parentData.name || editMode ? (
        <Card fluid>
            <Card.Content>
                {!editMode && (
                    <Card.Header id="header">
                        <Header as="h2">{parentData.name}</Header>
                    </Card.Header>
                )}
                <Card.Description>
                    <List>
                        {editMode && (
                            <List.Item>
                                <CardRow data={parentData.name} editMode={editMode} labelName="name" setValue={handleChange} />
                            </List.Item>
                        )}
                        <List.Item>
                            <CardRow data={parentData.job} editMode={editMode} labelName="job" setValue={handleChange} />
                        </List.Item>
                        <List.Item>
                            <CardRowForDate data={parentData.birthDate} editMode={editMode} labelName="birthDate" setValue={handleChange} />
                        </List.Item>
                        <List.Item>
                            <CardRowForDate data={parentData.deathDate} editMode={editMode} labelName="deathDate" setValue={handleChange} />
                        </List.Item>
                        <List.Item>
                            <CardDropdownReligion data={parentData.religion} editMode={editMode} labelName="religion" setValue={handleChange} />
                        </List.Item>
                    </List>
                </Card.Description>
            </Card.Content>
        </Card>
    ) : null;
};

export default ParentCard;

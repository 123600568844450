import React, { useEffect, useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import { Button, Container, Dropdown, DropdownItemProps, Form } from "semantic-ui-react";

import IPriestMin, { NewPriestMin } from "../../data/priest/PriestMin";
import useFetchData from "../../requests/useFetchData";

interface IParishPlacementDripdownProps {
    setParish(
        priest: IPriestMin,
        type: string,
        startYear: string,
        startMonth: string,
        startDay: string,
        endYear: string,
        endMonth: string,
        endDay: string
    ): any;
    placementType: string;
}

const PlacementDropdown: React.FC<IParishPlacementDripdownProps> = (props: IParishPlacementDripdownProps) => {
    const { setParish, placementType } = props;

    const { response, loading } = useFetchData<IPriestMin[]>("/priests");

    const [options, setOptions] = useState<DropdownItemProps[]>([]);
    const [selected, setSelected] = useState<IPriestMin>(NewPriestMin);
    const [startYear, setStartYear] = useState("");
    const [startMonth, setStartMonth] = useState("");
    const [startDay, setStartDay] = useState("");
    const [endYear, setEndYear] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const [endDay, setEndDay] = useState("");

    const handleChange = (event: any, { value }: any) => {
        response?.forEach((priest) => {
            if (priest.ID == value) {
                setSelected(priest);
            }
        });
    };

    const handleSubmit = () => {
        setParish(selected, placementType, startYear, startMonth, startDay, endYear, endMonth, endDay);
        window.location.reload();
    };

    useEffect(() => {
        if (response) {
            const newOptions = [];
            for (const priest of response) {
                newOptions.push({
                    key: priest.ID,
                    text: priest.name + " ( " + priest.birthTown + " ," + priest.birthDate.year + " )",
                    value: priest.ID
                });
            }
            setOptions(newOptions);
        } else {
            setOptions([]);
        }
    }, [response]);

    return (
        <>
            <Container style={{ marginBottom: "40px", height: "30px" }}>
                <Dropdown button loading={loading} onChange={handleChange} options={options} search style={{ width: "50%", marginTop: "15px" }} />
            </Container>
            <FM id="profile.startDate" />
            <Form.Input
                name="year"
                onChange={(event: any, { value }: any) => {
                    setStartYear(value);
                }}
                style={{ width: "6em" }}
                type="string"
                placeholder="year"
            />
            <Form.Input
                name="month"
                onChange={(event: any, { value }: any) => {
                    setStartMonth(value);
                }}
                style={{ width: "6em" }}
                type="string"
                placeholder="month"
            />
            <Form.Input
                name="day"
                onChange={(event: any, { value }: any) => {
                    setStartDay(value);
                }}
                style={{ width: "6em" }}
                type="string"
                placeholder="day"
            />
            <FM id="profile.endDate" />
            <Form.Input
                name="endYear"
                onChange={(event: any, { value }: any) => {
                    setEndYear(value);
                }}
                style={{ width: "6em" }}
                type="string"
                placeholder="year"
            />
            <Form.Input
                name="endMonth"
                onChange={(event: any, { value }: any) => {
                    setEndMonth(value);
                }}
                style={{ width: "6em" }}
                type="string"
                placeholder="month"
            />
            <Form.Input
                name="endDay"
                onChange={(event: any, { value }: any) => {
                    setEndDay(value);
                }}
                style={{ width: "6em" }}
                type="string"
                placeholder="day"
            />
            <Button icon="plus blue" onClick={handleSubmit} style={{ marginLeft: "20px", marginTop: "15px" }} />
        </>
    );
};

export default PlacementDropdown;

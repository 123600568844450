import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Checkbox } from "semantic-ui-react";

import "../../../static/semanticUiOverwrites.css";

interface IUncertainCheckboxProps {
    handleUncertainChange: () => void;
    uncertain: boolean;
}

const UncertainCheckbox: React.FC<IUncertainCheckboxProps> = (props: IUncertainCheckboxProps) => {
    const { uncertain, handleUncertainChange } = props;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox className="uncertainCheckbox" defaultChecked={uncertain} onChange={handleUncertainChange} toggle />
            <FM id="label.uncertain" />
        </div>
    );
};

export default UncertainCheckbox;

enum Religion {
    REFORMED = "reformed",
    UNITARIAN = "unitarian",
    LUTHERAN = "lutheran",
    SAXONLUTHERAN = "saxonlutheran",
    CATHOLICISM = "catholicism",
    ORTHODOX = "orthodox"
}

export default Religion;

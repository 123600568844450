import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Header, Menu } from "semantic-ui-react";

interface IAdminControllerProps {
    activeMenu: string;
    setActiveMenu(newActiveMenu: string): void;
}

const AdminController: React.FC<IAdminControllerProps> = (props: IAdminControllerProps) => {
    const { activeMenu, setActiveMenu } = props;

    const handleMenuChange = (e: any, { name }: any) => {
        setActiveMenu(name);
    };

    return (
        <>
            <Header as="h1">
                <FM id="menu.admin" />
            </Header>
            <Menu color="violet" fluid secondary size="huge" vertical>
                <Menu.Item active={activeMenu === "all"} name="all" onClick={handleMenuChange}>
                    <FM id="auth.roles.all" />
                </Menu.Item>
                <Menu.Item active={activeMenu === "admin"} name="admin" onClick={handleMenuChange}>
                    <FM id="auth.roles.admin" />
                </Menu.Item>
                <Menu.Item active={activeMenu === "editor"} name="editor" onClick={handleMenuChange}>
                    <FM id="auth.roles.editor" />
                </Menu.Item>
                <Menu.Item active={activeMenu === "reader"} name="reader" onClick={handleMenuChange}>
                    <FM id="auth.roles.reader" />
                </Menu.Item>
                <Menu.Item active={activeMenu === "guest"} name="guest" onClick={handleMenuChange}>
                    <FM id="auth.roles.guest" />
                </Menu.Item>
                <Menu.Item active={activeMenu === "pending"} name="pending" onClick={handleMenuChange}>
                    <FM id="auth.roles.pending" />
                </Menu.Item>
                <Menu.Item active={activeMenu === "refused"} name="refused" onClick={handleMenuChange}>
                    <FM id="auth.roles.refused" />
                </Menu.Item>
            </Menu>
        </>
    );
};

export default AdminController;

import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Container, Dropdown, Form, Header } from "semantic-ui-react";

import ISuggestionFilter from "../../data/suggestion/SuggestionFilters";
import BoldFM from "../../util/styledMessages/BoldFM";

interface ISearchPriestsControllerProps {
    filters: ISuggestionFilter;
    setFilters(newFilters: object): void;
    resetPagination(): void;
}

const SearchSuggestionsController: React.FC<ISearchPriestsControllerProps> = (props: ISearchPriestsControllerProps) => {
    const { resetPagination, filters, setFilters } = props;

    const targetTypeOptions = ["all", "priest", "parish"].map((status) => ({ key: status, text: <FM id={`label.${status}`} />, value: status }));

    const acceptedOptions = ["active", "accepted", "declined"].map((status) => ({ key: status, text: <FM id={`label.${status}`} />, value: status }));

    const handleTypeChange = (event: any, data: any) => {
        let { value } = data;
        if (value === "all") {
            value = "";
        }
        setFilters({
            ...filters,
            ["targetType"]: value
        });
    };

    const handleFilterInputChange = (event: any) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
        resetPagination();
    };

    const handleAcceptedChange = (event: any, data: any) => {
        let { value } = data;
        if (value === "active") {
            value = undefined;
        }
        setFilters({
            ...filters,
            ["accepted"]: value
        });
        resetPagination();
    };

    return (
        <Form>
            <Container style={{ marginBottom: "4em" }}>
                <Header as="h2">
                    <FM id="suggestion.info.header" />
                </Header>
                <Header as="h4">
                    <FM id="suggestion.info" />
                </Header>
            </Container>
            <Form.Input
                id="proposer"
                label={<BoldFM id={"suggestion.filter.proposer"} style={{ fontSize: "13px" }} />}
                name="proposer"
                onChange={handleFilterInputChange}
                value={filters.proposer}
            />
            <BoldFM id="suggestion.filter.targetType" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Field width={13}>
                    <Dropdown
                        defaultValue={filters.targetType === "" ? "all" : filters.targetType}
                        name="targetType"
                        onChange={handleTypeChange}
                        options={targetTypeOptions}
                        selection
                    />
                </Form.Field>
            </Form.Group>
            <Form.Input
                id="targetName"
                label={<BoldFM id={"suggestion.filter.targetName"} style={{ fontSize: "13px" }} />}
                name="targetName"
                onChange={handleFilterInputChange}
                value={filters.targetName}
            />

            <BoldFM id="suggestion.state" style={{ fontSize: "13px" }} />
            <Form.Group inline style={{ marginTop: "0.5em", marginBottom: "2em" }} unstackable>
                <Form.Field width={13}>
                    <Dropdown
                        defaultValue={filters.accepted ? filters.accepted : "active"}
                        name="accepted"
                        onChange={handleAcceptedChange}
                        options={acceptedOptions}
                        selection
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    );
};

export default SearchSuggestionsController;

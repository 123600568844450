import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { NavLink } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";

import IMainPlacement from "../../../../data/priest/MainPlacement";
import DateDisplay from "../../../DateDisplay";

interface IMainPriestPlaceMarkerProps {
    placement: IMainPlacement;
}

const MainPriestPlaceMarker: React.FC<IMainPriestPlaceMarkerProps> = (props: IMainPriestPlaceMarkerProps) => {
    const { placement } = props;

    return (
        <Marker key={placement.ID} position={[placement.coordinates.lat || 0, placement.coordinates.lng || 0]}>
            <Popup>
                <Header as="h2" className="hoverLink">
                    <NavLink to={`/parishes/profile/${placement.placeID}`}>{placement.place}</NavLink>
                </Header>
                <Container>
                    <FM id="profile.mainPriestPlace" />
                    <div>
                        <FM id="profile.numberOfPeople" />: {placement.numberOfPeople}
                    </div>
                    <div>
                        <FM id="profile.startDate" />: <DateDisplay date={placement.startDate} />
                    </div>
                    <div>
                        <FM id="profile.endDate" />: <DateDisplay date={placement.endDate} />
                    </div>
                    <div>
                        <FM id="profile.munimentNumber" />: {placement.munimentNumber}
                    </div>
                    <div>
                        <FM id="profile.munimentIssuingAuthority" />: {placement.munimentIssuingAuthority}
                    </div>
                </Container>
            </Popup>
            <Tooltip>{placement.place}</Tooltip>
        </Marker>
    );
};

export default MainPriestPlaceMarker;

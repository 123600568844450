/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import IDate from "../../data/Date";
import IChild from "../../data/priest/Child";
import IPriestProfileData from "../../data/priest/PriestProfileData";
import IQualification from "../../data/priest/Qualification";
import ISpouse from "../../data/priest/Spouse";

const dateToString = (date: IDate) => {
    return date.year && date.month && date.day ? date.year + "." + date.month + "." + date.day : "";
};

const stringFromChildren = (children: IChild[]) => {
    return children ? children.map((child) => child.name).join(",") : "";
};

const stringFromSpouses = (spouses: ISpouse[]) => {
    return spouses ? spouses.map((spouse) => spouse.name).join(",") : "";
};

const stringFromQualifications = (qualifications: IQualification[]) => {
    return qualifications ? qualifications.map((qualifications) => qualifications.diplomaName).join(",") : "";
};

export const mapToExportedPriestData = (priest: IPriestProfileData) => {
    return {
        ID: priest.ID,
        name: priest.name,
        verified: priest.verified,
        birthTown: priest.birthTown,
        birthDate: dateToString(priest.birthDate),
        birthDateComment: priest.birthDate.comment,
        deathDate: dateToString(priest.deathDate),
        deathDateComment: priest.deathDate.comment,
        subscriptionDate: dateToString(priest.subscriptionDate),
        subscriptionDateComment: priest.subscriptionDate.comment,
        birthCountry: priest.birthCountry,
        birthCounty: priest.birthCounty,
        graduationDate: dateToString(priest.graduationDate),
        diocese: priest.diocese,
        consecrationDate: dateToString(priest.consecration.date),
        consecrationPlace: priest.consecration.place,
        isMarried: priest.isMarried,
        isWidow: priest.isWidow,
        isDivorced: priest.isDivorced,
        father: priest.father.name,
        mother: priest.mother.name,
        retirementDate: dateToString(priest.retirementDate),
        children: stringFromChildren(priest.children),
        spouses: stringFromSpouses(priest.spouses),
        qualifications: stringFromQualifications(priest.qualifications),
        speakingSkillsLess: priest.speakingSkills.less,
        speakingSkillsMedium: priest.speakingSkills.medium,
        speakingSkillsWell: priest.speakingSkills.well,
        writingSkillsLess: priest.writingSkills.less,
        writingSkillsMedium: priest.writingSkills.medium,
        writingSkillsWell: priest.writingSkills.well,
        army: priest.army,
        resigned: dateToString(priest.resigned),
        fired: dateToString(priest.fired),
        note: priest.note,
        religion: priest.religion,
        studyStatus: priest.studyStatus
    };
};
